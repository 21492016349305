
<!-----------------------------------
  コンテンツヘッダ
  コンテンツのヘッダ部を表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="text-center">
    <h1>ただいまメンテナンス中です</h1>
    <p>ご不便をおかけし申し訳ございません。</p>
    <p class="mb-3">メンテナンス終了までしばらくお待ちください。</p>
    <p v-if="cStartDate != null" class="text-red fw-bold">メンテナンス時間：{{ cStartDate }} ～ {{ cEndDate }}</p>
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "MaintenancePage",

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    startDate: String,
    endDate: String,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup(props) {
    const cStartDate = computed(() => props.startDate);
    const cEndDate = computed(() => props.endDate);

    return { cStartDate, cEndDate };
  },

});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>

</style>
