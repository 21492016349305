<!-----------------------------------
  ユーザー登録状況
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <span>{{ text }}</span>
  <button class="btn btn btn-light btn-sm filter-btn" type="button" @click.stop="onDropDownClick()">
    <img v-if="!headerContext.column.getIsFiltered()" src="@/assets/table/filter-off.png" alt="filter-off" />
    <img v-else src="@/assets/table/filter-on.png" alt="filter-on" />
  </button>
  <button class="hide-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"
    @click.stop="onDropDownFocus()" ref="DropDownBtn"></button>

  <form class="dropdown-menu p-3 filter-form" @submit.prevent @click.stop>
    <div class="d-flex flex-column">
      <div v-if="options.text">
        <input type="text" class="form-control form-control-sm" v-model="textFilter" placeholder="検索ワード"
          @keydown.enter="onTextEnter" ref="TextFilter" />
      </div>
      <template v-if="options.check">
        <div v-for="headerGroup in checkTable.getHeaderGroups()" :key="headerGroup.id" class="mt-1">
          <div class="form-check form-check-inline mx-1">
            <template v-for="header in headerGroup.headers" :key="header.id">
              <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
            </template>
          </div>
        </div>
        <perfect-scrollbar class="flex-grow-1 check-area">
          <div v-for="row in checkTable.getRowModel().rows" :key="row.id">
            <div class="form-check form-check-inline mx-3">
              <template v-for="cell in row.getVisibleCells()" :key="cell.id">
                <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
              </template>
            </div>
          </div>
        </perfect-scrollbar>
      </template>
    </div>
  </form>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { computed, defineComponent, ref } from "vue";
import { FlexRender, getCoreRowModel, useVueTable } from "@tanstack/vue-table";

export default defineComponent({
  name: "TanstackTableFilter",

  components: {
    FlexRender,
  },

  props: {
    text: String,
    /** @type {{new(): import("@tanstack/vue-table").HeaderContext<any, any>}} */
    headerContext: Object,
    dataSource: Array,
    options: {
      /**
       * @type {{new():{
       *  text: boolean;
       *  check: boolean;
       * }}}
       */
      type: Object,
      default() {
        return {
          text: true,
          check: true,
        };
      }
    },
    enableFilter: Boolean,
  },

  setup(props) {

    // チェック項目の作成
    const checkList = computed(() => {
      const tag = new Set();
      if (props.dataSource != null) {
        for (const d of props.dataSource) {
          tag.add(d[props.headerContext.header.id])
        }
      }
      const selecter = Array.from(tag);
      selecter.sort();
      const list = [];
      for (const s of selecter) {
        list.push({ value: String(s) });
      }
      return list;
    });

    /** @type {import("@tanstack/vue-table").ColumnDef<ListFinderUser,any>[]} */
    const columns = [
      {
        id: "select",
        header: ({ table }) => {
          return (
            <input type="checkbox" id={`filter-check-${props.headerContext.header.index}-all`}
              class="form-check-input"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()} />
          );
        },
        cell: ({ row }) => {
          return (
            <input type="checkbox" id={`filter-check-${props.headerContext.header.index}-${row.id}`}
              class="form-check-input"
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()} />
          );
        },
      },
      {
        id: "value",
        header: () => {
          return (
            <label class="h-100" for={`filter-check-${props.headerContext.header.index}-all`}>(全て選択)</label>
          );
        },
        cell: (info) => {
          return (
            <label class="h-100" for={`filter-check-${props.headerContext.header.index}-${info.row.id}`}>{
              info.row.original[info.column.id]
            }</label>
          );
        },
      },
    ];
    const rowSelection = ref({});

    /** @type {import("@tanstack/vue-table").TableOptions<any>} */
    const options = {
      getCoreRowModel: getCoreRowModel(),
      get data() { return checkList.value },
      get columns() { return columns },

      state: {
        get rowSelection() {
          return rowSelection.value;
        },
      },
      enableRowSelection: true,
      onRowSelectionChange: (state) => { rowSelection.value = state(rowSelection.value); },
    };
    const checkTable = useVueTable(options);
    return { checkTable, checkList, rowSelection };
  },

  data() {
    return {
      textFilter: "",
    };
  },

  mounted() {
    const filter = this.headerContext.column.getFilterValue();
    if (filter != null) {
      this.setFilterValue(filter);
    } else {
      this.checkTable.toggleAllRowsSelected(true);
    }
  },

  watch: {
    enableFilter: function (newVal) {
      if (newVal == false) {
        this.clear();
      }
    },
    textFilter: function (newVal) {
      if (!this.options.check) {
        this.headerContext.column.setFilterValue(newVal);
      } else if (newVal != "") {
        const rows = this.checkTable.getRowModel().rows;
        for (const row of rows) {
          const cells = row.getVisibleCells();
          for (const cell of cells) {
            if (cell.column.id == "value") {
              const selecter = cell.row.original[cell.column.id];
              if (selecter.includes(newVal)) {
                row.toggleSelected(true);
              }
              else {
                row.toggleSelected(false);
              }
            }
          }
        }
      }
    },
    rowSelection: {
      deep: true,
      handler: function (newVal) {
        if (!this.options.check) {
          // チェック検索以外は処理を行わない
          return;
        }
        if (this.checkTable.getIsAllRowsSelected()) {
          this.headerContext.column.setFilterValue([]);
        } else {
          const list = [];
          for (const key in newVal) {
            list.push(this.checkList[key].value);
          }
          if (0 == list.length) {
            list.push("?_$Dummey Filter$_?");
          }
          this.headerContext.column.setFilterValue(list);
        }
      },
    },
  },

  methods: {
    /**
     * テキストのEnterキー入力
     * @param {KeyboardEvent} event 
     */
    onTextEnter(event) {
      // 日本語入力中のEnterキー操作は無効にする
      if (event.keyCode !== 13) {
        return;
      }

      document.body.click();
    },

    /** フィルタクリア */
    clear() {
      this.textFilter = "";
      if (this.checkTable != null) {
        this.checkTable.toggleAllRowsSelected(true);
      }
    },

    /**
     * フィルタ設定
     * @param {string | string[]} filter 
     */
    setFilterValue(filter) {

      if (filter != null) {
        if (!this.options.check && typeof filter === "string") {
          this.textFilter = filter;
        }
        else if (this.options.check && Array.isArray(filter)) {
          const rows = this.checkTable.getRowModel().rows;
          for (const row of rows) {
            const cells = row.getVisibleCells();
            for (const cell of cells) {
              if (cell.column.id == "value") {
                const selecter = cell.row.original[cell.column.id];
                if (filter.includes(selecter)) {
                  row.toggleSelected(true);
                }
                else {
                  row.toggleSelected(false);
                }
              }
            }
          }
        }
      }
    },

    /** ドロップダウン表示 */
    onDropDownClick() {
      // 他のドロップダウンを全て閉じる
      document.body.click();

      // テキストボックスにフォーカスを移動する
      const dropDownBtn = this.$refs["DropDownBtn"];
      if (dropDownBtn != null) {
        dropDownBtn.click();
      }
    },
    onDropDownFocus() {
      // テキストボックスにフォーカスを移動する
      const textFilterDom = this.$refs["TextFilter"];
      if (textFilterDom != null) {
        textFilterDom.focus();
      }
    }
  },

});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
.filter-form {
  width: 20rem;
  font-weight: normal;
}

.filter-form .check-area {
  max-height: 30rem;
}

.filter-btn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.filter-btn>img {
  width: 1rem;
  height: 1rem;
}

.hide-btn {
  width: 0px;
  height: 0px;
  padding: 0px;
  border: none;
}
</style>
