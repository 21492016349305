
<!-----------------------------------
  ヘッダー
  画面最上部のヘッダーの表示を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div>
    <div class="d-flex text-nowrap header-res">
      <div v-if="!IsMobile" class="logo">
        <!-- PC表示のみロゴを表示 -->
        <img style="height: 100%;" src="@/assets/Type-2_V_3D.png" alt="Logo" />
        <img style="height: 60%;" src="@/assets/title.png" alt="Logo" />
      </div>
      <div class="flex-grow-1 d-flex align-items-center ps-1">
        <div class="btn-group">
          <button type="button" class="btn dropdown-toggle border title" data-bs-toggle="dropdown"
            aria-expanded="false">DDR Member Site</button>
          <ul class="dropdown-menu atlas-dropdown">
            <li v-for="(item, index) of msLinkList" :key="index">
              <a class="dropdown-item" :href="item.href" target="_blank" rel="noopener noreferrer">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex flex-column text-end pe-1 profile">
        <span class="text-white mb-auto"> {{ userName }}様 </span>
        <span class="link">
          <a href="#" data-bs-toggle="modal" data-bs-target="#ProfileModal" ref="ProfileLink">プロファイル変更</a>
          <span>・</span>
          <a href="#" @click="logout()">ログアウト</a>
        </span>
      </div>
    </div>

    <!-- プロフィールポップアップ -->
    <teleport to="body">
      <div id="ProfileModal" class="modal" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                ref="ProfileModalClose"></button>
            </div>
            <div class="modal-body">
              <UserConfigVue :user-name="userName" @show-config-modal="showProfileModal" />
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, } from "vue";
import { Auth } from "aws-amplify";
import UserConfigVue from "@/components/Utility/UserConfig.vue";
import ContentDefine from "@/helpers/ContentDefine";
import LogHelper from "@/helpers/LogHelper";

export default defineComponent({
  name: "MasterHeader",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    UserConfigVue,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    userName: String,
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() { return {}; },

  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    IsMobile() {
      return this.$store.state.IsMobile;
    },
    msLinkList() {
      return [
        { name: "プレゼンテーション", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/presentation/index.html` },
        { name: "ドクターズインタビュー", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/interview/index.html` },
        { name: "学会・セミナー", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/event/index.html` },
        { name: "セミナーレポートトップ", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/report/index.html` },
        { name: "導入施設使用経験", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/usage/index.html` },
        { name: "撮影ワークフロー", href: `${ContentDefine.msDomain}/healthcare/ddrms/members/workflow/index.html` },
      ];
    }
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * ログアウト実行
     */
    logout() {
      LogHelper.postLog(LogHelper.log.logout, null, null, null);

      this.$store.commit("logout");
      Auth.signOut();
    },

    /**
     * プロファイル表示
     */
    showProfileModal() {
      /** @type {HTMLElement} */
      const link = this.$refs["ProfileLink"];
      link.click();
    },
  },
});
</script>

<!-----------------------------------
  スタイル
---->
<style scoped>
.header-res {
  height: var(--ddr-header-height);
  background-color: var(--ddr-header-background);
}

.logo {
  width: var(--ddr-sidemenu-width);
}

.header-res img {
  max-width: min(100%, 40vw);
}

.header-res .title {
  color: white;
  border-radius: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.header-res .title:focus {
  box-shadow: none;
}

.header-res .profile {
  font-size: 16px;
}

.atlas-dropdown {
  border-radius: 0px;
  background-color: var(--ddr-header-background);
  border-color: white;
  color: white;
}

.atlas-dropdown .dropdown-item {
  color: rgb(191, 191, 191);
}

.dropdown-item:hover {
  background-color: rgb(0, 32, 96);
  color: rgb(255, 255, 255);
}

.link>a {
  color: rgb(65, 161, 255);
}
</style>
