
<!-----------------------------------
  KOLユーザーブロック
  KOLユーザーのブロック画面を表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="full-size">
    <div class="h-100 d-flex flex-column justify-content-between">
      <div class="img-size">
        <img src="@/assets/Web_H_3D_Type-1_51px.jpg" class="h-100" />
      </div>
      <div class="text-center">
        <div class="fs-3">DDRAtlas事前確認用サイトは現在閉鎖中です。</div>
        <div class="fs-3">下記のサイトで同じ内容を閲覧できます。</div>
        <div class="fs-3">＜<a :href="url">DDRAtlas</a>＞</div>
      </div>
      <div class="img-size"></div>
    </div>

  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "KOLBlockPage",

  //*****************************
  // 初期化処理
  //*****************************
  setup() {
    const url = ref(`${process.env.VUE_APP_ATLAS_DOMAIN}/Atlas/`);
    return { url };
  },
  data() { return {}; },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
.full-size {
  width: 100dw;
  width: 100dvw;
  height: 100dh;
  height: 100dvh;
}

.img-size {
  height: var(--ddr-header-height);
}
</style>
