/** アトラスサイトオリジン */
const atlasOrigin = window.location.origin;
/** メンバーサイトオリジン */
const msOrigin = process.env.VUE_APP_MS_DOMAIN;

const region = process.env.VUE_APP_COGNITO_REGION;
const userPoolId = process.env.VUE_APP_COGNITO_USER_POOL;
const userPoolWebClientId = process.env.VUE_APP_COGNITO_USER_POOL_CLIENT;
const domain = process.env.VUE_APP_COGNITO_DOMAIN;

/** @type {import("@aws-amplify/auth/lib-esm/types/Auth").AuthOptions} */
const Auth = {
  region: region,
  userPoolId: userPoolId,
  userPoolWebClientId: userPoolWebClientId,
  oauth: {
    domain: domain,
    scope: ["openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: `${atlasOrigin}/Atlas/index.html`,
    redirectSignOut: `https://${domain}/logout?client_id=${userPoolWebClientId}&logout_uri=${msOrigin}/healthcare/ddrms/members/logout.html`,
    responseType: "code",
  },
  // endpoint: "https://ddrms.auth.km-ddrpf-stage.com",
  // cookieStorage: {
  //   path: "/Atlas/",
  //   expires: 1,
  //   sameSite: "lax",
  //   secure: true,
  //   domain: "localhost",
  // },
};

export { Auth };
