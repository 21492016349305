<!-----------------------------------
    パスワード変更画面
    パスワードの変更を行う
----------------------------------->

<!-----------------------------------
    テンプレート
----------------------------------->
<template>
  <div class="container-fluid">
    <form @submit.prevent="chengePassword()">
      <div class="row mb-1">
        <div class="col fs-5 fw-bold">パスワード変更</div>
      </div>
      <div class="row mb-1 align-items-center d-none">
        <div class="col-3 d-flex justify-content-between align-items-center label">
          <label for="userID" class="col-form-label">メールアドレス</label>
          <span class="required"></span>
        </div>
        <div class="col-9">
          <input class="form-control" type="email" v-model="email" maxlength="255" autofocus="" name="userID" id="userID"
            autocomplete="email" readonly required />
        </div>
      </div>
      <div class="row mb-1 align-items-center">
        <div class="col-3 d-flex justify-content-between align-items-center label">
          <label for="oldPassword" class="col-form-label">現在のパスワード</label>
          <span class="required"></span>
        </div>
        <div class="col-9">
          <input id="oldPassword" class="form-control" type="password" v-model="oldPassword" name="oldPassword"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\^$*.[\]{}()?\-&quot;!@#%&/\\,><':;|_~`+=]{8,}$"
            title="少なくとも1つの大文字、小文字、数字を含む、8文字以上のパスワードを設定してください。" autocomplete="current-password" required />
        </div>
      </div>
      <div class="row mb-1 align-items-center">
        <div class="col-3 d-flex justify-content-between align-items-center label">
          <label for="password1" class="col-form-label">パスワード</label>
          <span class="required"></span>
        </div>
        <div class="col-9">
          <input id="password1" class="form-control" type="password" v-model="newPassword1" name="password1"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\^$*.[\]{}()?\-&quot;!@#%&/\\,><':;|_~`+=]{8,}$"
            title="少なくとも1つの大文字、小文字、数字を含む、8文字以上のパスワードを設定してください。" autocomplete="new-password" required />
        </div>
      </div>
      <div class="row mb-1 align-items-center">
        <div class="col-3 d-flex justify-content-between align-items-center label">
          <label for="password2" class="col-form-label">パスワード(確認)</label>
          <span class="required"></span>
        </div>

        <div class="col-9">
          <input id="password2" class="form-control" type="password" v-model="newPassword2" name="password2"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\^$*.[\]{}()?\-&quot;!@#%&/\\,><':;|_~`+=]{8,}$"
            title="少なくとも1つの大文字、小文字、数字を含む、8文字以上のパスワードを設定してください。" autocomplete="new-password" required />
        </div>
      </div>

      <div class="row mb-3 justify-content-center">
        <div class="col-auto">
          <span class="error-message">{{ errorMessage }}</span>
        </div>
      </div>
      <div class="row mb-3 justify-content-center">
        <div class="col-auto">
          <button class="btn btn-primary" type="submit">
            パスワード変更
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<!-----------------------------------
    スクリプト
---->
<script lang="js">
import { defineComponent, ref } from "vue";
import { Auth } from "aws-amplify";

export default defineComponent({
  name: "ChangePasswordView",

  //*****************************
  // 継承イベント定義
  //*****************************
  emits: {
    /** @type {()=>void} */
    completed: null,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup() {
    const email = ref("");
    Auth.currentAuthenticatedUser().then((user) => {
      email.value = user.attributes?.email;
    });
    return { email };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
      errorMessage: "",
    };
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * パスワード変更実施
     */
    async chengePassword() {
      if (this.newPassword1 != this.newPassword2) {
        this.errorMessage = "パスワードとパスワード確認が異なっています";
      } else {
        try {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, this.oldPassword, this.newPassword1);

          alert("パスワード変更に成功しました");
          this.clear();
          this.$emit("completed");
        } catch (error) {
          this.errorMessage = "パスワードが違います";
        }
      }
    },

    /**
     * データクリア
     */
    clear() {
      this.oldPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";
      this.errorMessage = "";
    },
  },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
.label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: #e9e9e9;
}

.label .required::after {
  content: "※";
  color: orangered;
}

.error-message {
  color: orangered;
}
</style>
