import axios from "@/helpers/AxiosHelper";

/**
 * 指定されたhtmlの相対パスを置換する
 * @param {string} html 変換対象のテキスト
 * @param {string} url ドキュメントURL
 * @returns 変換データ
 */
export function convertRelativePath(html, url) {
  const baseUrl = url.slice(0, url.lastIndexOf("/"));
  return html.replace(/src=("|').\//gi, `src=$1${baseUrl}/`);
}

/**
 * 指定されたhtmlのパスをリソースパスに置換する
 * @param {string} html 変換対象のテキスト
 * @returns 変換データ
 */
export function convertResourcePath(html) {
  return html.replace(/src=("|')\/?Content\//gi, "src=$1Resource/");
}
/**
 * 指定されたhtmlの患者データを置換する
 * @param {string} html 変換対象のテキスト
 * @returns 変換データ
 */
export async function convertPatientData(html) {

  // PatientDataタグ抽出用の正規表現
  const regPatientData = /<PatientData[^>]*data-ddr-patient=(?:"|')([^"']*)(?:"|')[^>]*>[\n\S\s]*?<\/PatientData>/gi;

  // 患者IDの抽出
  const patientSet = new Set();
  const matchList = html.match(regPatientData) ?? [];
  for (const elem of matchList) {
    const attr = elem.match(/data-ddr-patient=(?:"|')([^"']*)(?:"|')/i);
    if (attr[1] != null) {
      patientSet.add(attr[1]);
    }
  }

  // 患者データ取得
  const patientList = await getPatientData(patientSet);

  // htmlデータの置き換え
  const convertHtml = html.replace(regPatientData, (match, ddrPatient) => {
    let rep = match;
    // タグの置き換え
    rep = rep.replace("<PatientData", "<div");
    rep = rep.replace("</PatientData", "</div");

    // 患者カラムの置き換え
    rep = rep.replace(/{{ *?([^ ]*?) *?}}/gi, (_m, prop) => {
      return patientList[ddrPatient]?.[prop.toLowerCase()] ?? "";
    });
    return rep;
  });

  return convertHtml;
}
/**
 * 患者データ取得
 * @param {Set<string>} patientSet 患者IDセット
 * @returns 患者データリスト
 */
async function getPatientData(patientSet) {
  /**
   * @type {{
   *   content_id: string;
   *   patient_id: string;
   *   gender: string;
   *   age: string;
   *   bmi: string;
   *   height: string;
   *   url: string;
   *   disease: string;
   * }[]}
   */
  const patientList = [];

  for (const patient of patientSet) {
    const data = await axios.get(`Resource/PatientData/Data_${patient}/Data_${patient}.json`);

    patientList[patient] = data.data;
    if (patientList[patient] != null) {
      // 性別の変換
      switch (patientList[patient].gender) {
        case "M": patientList[patient].gender = "男性"; break;
        case "F": patientList[patient].gender = "女性"; break;
        default: patientList[patient].gender = ""; break;
      }
      const disease = patientList[patient].disease;
      // 疾患の変換
      patientList[patient].disease = disease?.[0] ?? "正常";

      // コンテンツIDの設定
      patientList[patient].content_id = getContentId(patient);
      // 患者IDの設定
      patientList[patient].patient_id = patient;
      // URLの設定
      patientList[patient].url = `Resource/PatientData/Data_${patient}`;
    }

    // キーを小文字に変換
    for (const key in patientList[patient]){
      patientList[patient][key.toLowerCase()] = patientList[patient][key];
    }
  }
  console.log("患者データ取得完了");

  return patientList;
}

/**
 * コンテンツIDを取得する
 * @param {string} patientId 患者ID
 * @returns コンテンツID
 */
function getContentId(patientId) {
  const vuex = JSON.parse(sessionStorage.getItem("vuex"));
  const patient = findPatient(patientId, vuex.ContentTree);
  return patient?.id;
}

/**
 * 患者データの検索
 * @param {string} patientId 
 * @param {import("@/helpers/type").ContentData[]} contents 
 */
function findPatient(patientId, contents) {
  if (contents == null) {
    return null;
  }
  for (const content of contents) {
    if (content.patientId == patientId) {
      return content;
    } else {
      const child = findPatient(patientId, content.child);
      if (child != null) {
        return child;
      }
    }
  }
  return null;
}
