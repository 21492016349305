import videojs from "video.js"
import axios from "@/helpers/AxiosHelper";
import * as convert from "@/helpers/ContentConvert";
import { IsLocal } from "./IsLocal";

/**
 * コンテンツ表示で使用する共通関数
 */
export default {
  /**
   * 検索ワード強調表示
   * @param {string} value HTML
   * @param {string} searchKey 検索ワード
   * @returns {string} 強調表示後HTML
   */
  searchHighlight: function (value, searchKey) {
    if (searchKey && value) {
      try {
        // 検索キーの置換
        const escSearchKey = searchKey.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        const searchRegExp = new RegExp(escSearchKey, "ig");

        // テキストデータ置換
        const replaceText = (element) => {
          return element.replace(searchRegExp, (match) => {
            return `<span style="background-color: yellow; color: black">${match}</span>`;
          });
        };

        // HTMLデータ置換
        const replaceHTML = (body) => {
          // <!--[\s\S]+?--> <!--から始まり、-->で終わる文字
          // <\/?[^>]+> <から始まり、>で終わる文字
          // [^<]+ <以外の文字
          // (.*?)+ 任意の文字
          return body.replace(/<!--[\s\S]+?-->|<\/?[^>]+>|[^<]+|(.*?)+/ig, (element) => {
            if (element.includes("<") && element.includes(">")) {
              // 「<○○○>」「</○○○>」はそのままとする
              return element;
            } else {
              // 「<○○○>」「</○○○>」以外はハイライト置換を行う
              return replaceText(element);
            }
          });
        };

        // HTMLデータチェック
        const bodyRegExp = /<body[\s\S]*<\/body>/ig;
        if (bodyRegExp.test(value)) {
          // bodyタグ内をhtmlハイライト
          return value.replace(bodyRegExp, replaceHTML);
        } else {
          // 文字列ハイライト
          return replaceText(value);
        }
      } catch (e) {
        console.debug(e);
        return value;
      }
    }
    return value;
  },
  /**
   * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
   */

  /**
   * コンテンツIDから対象コンテンツデータを取得する
   * @param {ContentData} value コンテンツリスト
   * @param {string} id コンテンツID
   * @returns {ContentData} コンテンツデータ
   */
  serachContent: function (value, id) {
    if (value != null) {
      for (let i = 0; i < Object.keys(value).length; i++) {
        if (value[i].url != null) {
          if (value[i].id == id) {
            return value[i];
          }
        } else {
          var ret = this.serachContent(value[i].child, id);
          if (ret != null) {
            return ret;
          }
        }
      }
    }
    return null;
  },

  /**
   * テキストコンテンツを取得する
   * @param {string} url url
   */
  getTextContent: async function (url) {
    if (IsLocal) {
      // ローカル環境では患者データ変換をエミュレートする
      const res = await axios.get(url);
      /** @type {string} */
      let html = res.data;
      html = convert.convertRelativePath(html, url);
      html = await convert.convertPatientData(html);
      html = convert.convertResourcePath(html, url);
      res.data = html;
      return res;
    } else {
      return axios.get(url.replace(".html", "_dist.html"));
    }
  },

  /**
   * リソースURLに変換
   * @param {string} url url
   * @returns {string} リソースURL
   */
  replaceResource: function (url) {
    return url?.replace("Content/", "Resource/");
  },

  /**
   * 性別変換
   * @param {"M" | "F"} gender gender
   * @returns {"男性" | "女性"} 性別
   */
  replaceGender: function (gender) {
    return gender == "M" ? "男性" : "女性";
  },

  /**
   * 疾病変換
   * @param {string[]} disease disease
   * @returns {string} 代表疾病
   */
  replaceDisease: function (disease) {
    if (0 == disease.length) {
      return "所見/疾病なし";
    } else {
      return disease[0];
    }
  },

  VideoJs: class {
    /** video.jsのインスタンスリスト */
    #videojsInstanceList = [];
    /**
     * 動画にvideo.jsを適応
     * @param {Element} docDom 対象コンテンツタグ
     */
    set(docDom) {
      const option = {};
      const videoList = docDom.querySelectorAll("video-js");
      for (const elem of videoList) {
        this.#videojsInstanceList.push(videojs(elem, option));
      }
    }
    /**
     * video.jsのインスタンスを破棄
     */
    reset() {
      for (const elem of this.#videojsInstanceList) {
        elem.dispose();
      }
      this.#videojsInstanceList.length = 0;
    }
  },
};
