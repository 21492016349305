<!-----------------------------------
  マスターページ
  コンテンツの表示を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div id="MasterPage" class="container-fluid m-0 p-0" @click.left.capture="onLeftclick(value, $event)">
    <MasterHeader id="Header" :userName="UserName" />
    <!-- <UserHeader id="UserHeader"/> -->

    <!-- メインコンテンツ -->
    <div class="d-flex">
      <div v-if="!IsMobile">
        <!-- サイドメニュー -->
        <SideMenu id="SideMenu" :select="DispContent" :content="ContentTree" @click.right.prevent="dmy"
          @onRightclick="onRightclick" @selectMethod="selectMethod" @setSearchData="setSearchData" ref="SideMenuPC" />
      </div>
      <div class="flex-grow-1">
        <!-- コンテンツヘッダー -->
        <ContentHeader :content="DispContent" @reloadContent="reloadContent"
          @selectHistoryBeforeMethod="selectHistoryBeforeMethod"
          @selectHistoryPreviousMethod="selectHistoryPreviousMethod" />
        <!-- コンテンツ -->
        <ContentViewer :content="DispContent" :searchKey="searchKey" @selectMethod="selectMethod"
          @selectModalMethod="selectModalMethod" @menuOptionsRefresh="menuOptionsRefresh" />
      </div>
    </div>
    <!-- フッター -->
    <div id="footer" class="d-flex justify-content-end">
      <div class="align-self-center me-2">© Konica Minolta, Inc.</div>
    </div>

    <!-- 右クリックメニュー -->
    <span v-show="isMenuShow" class="ContextMenu" :style="menu_position" @click.right.prevent="dmy">
      <button v-if="isMenuEnable" @click="delBookmark" style="background: cyan">
        ブックマーク解除
      </button>
      <button v-else @click="onLeftclick" style="background: lightgray; color: gray">
        ブックマーク解除
      </button>
    </span>

    <!-- ポップアップ -->
    <teleport to="body">
      <div id="SubContent" class="modal" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          :class="{ 'text-modal-dialog': isPatientDataModal, 'data-modal-dialog': isPatientDataModal }">
          <div class="modal-content">
            <div class="modal-header">

              <button v-if="isPatientDataModal" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                aria-label="ContentRelay" @click="onModalPatientListRelayClick()">
                動画像一覧ページに移動
              </button>
              <button v-else type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="ContentRelay"
                @click="onModalRelayClick()">
                このページに移動
              </button>
              <template v-if="toggleModal == ''">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  ref="ModalClose"></button>
              </template>
              <template v-else>
                <button type="button" class="btn-close" :data-bs-target="toggleModal" data-bs-toggle="modal"
                  aria-label="Close" ref="ModalClose" @click="toggleModal = ''"></button>
              </template>
            </div>
            <div class="modal-body p-0">
              <ContentHeader :content="ModalDispContent" :isModal="true" @reloadContent="reloadContent"
                @selectHistoryBeforeMethod="selectHistoryBeforeMethod"
                @selectHistoryPreviousMethod="selectHistoryPreviousMethod" />
              <ContentViewer :content="ModalDispContent" :searchKey="searchKey" :isModal="true"
                @selectMethod="selectMethod" @menuOptionsRefresh="menuOptionsRefresh" />
            </div>
          </div>
        </div>
      </div>
    </teleport>

    <!-- サイドメニュー表示 -->
    <div v-if="IsMobile" class="offcanvas offcanvas-start w-auto" tabindex="-1" id="offcanvasSideMenu"
      aria-labelledby="offcanvasSideMenuLabel">
      <div class="offcanvas-header sidemenu-header">
        <h5 class="offcanvas-title" id="offcanvasSideMenuLabel"></h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
          ref="SideMenuClose"></button>
      </div>
      <div class="offcanvas-body p-0">
        <!-- サイドメニュー -->
        <SideMenu id="SideMenu" :select="DispContent" :content="ContentTree" @click.right.prevent="dmy"
          @onRightclick="onRightclick" @selectMethod="selectMethod" @setSearchData="setSearchData" ref="SideMenuMobile" />
      </div>
    </div>
  </div>
</template>

<!-----------------------------------
  スクリプト
---->
<script lang="js">
import { defineComponent } from "vue";
import MasterHeader from "@/components/MasterHeader.vue";
import SideMenu from "@/components/SideMenu.vue";
import ContentHeader from "@/components/ContentHeader.vue";
import ContentViewer from "@/components/ContentViewer.vue";
import ContentHelper from "@/helpers/ContentHelper";
import LogHelper from "@/helpers/LogHelper";
import { apiMenuGet, apiBookmarkDelete } from "@/helpers/ApiHelper";

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 *
 * @typedef {{
 *   UserName: string;
 *   DisplayContent: ContentData[];
 * }} FileTree getfiletreeレスポンス
 *
 */

export default defineComponent({
  name: "MasterPage",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    MasterHeader,
    SideMenu,
    ContentHeader,
    ContentViewer,
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /** @type {string | null} */
      searchKey: null,
      isMenuShow: false,
      isMenuEnable: false,
      /** @type {ContentData | null} */
      menuSelectValue: null,
      /** ブックマークメニュー表示場所 */
      menu_position: {
        top: 0,
        left: 0,
      },
      /** 患者データモーダル表示 */
      isPatientDataModal: false,
      /** モーダルのトグル表示 */
      toggleModal: "",
    };
  },
  //*****************************
  // 画面表示後処理
  //*****************************
  beforeMount() {
    this.initContent();
  },
  //*****************************
  // 算出プロパティ
  //*****************************
  computed: {
    ContentTree() {
      return this.$store.state.ContentTree;
    },
    DispContent() {
      return `${this.$store.state.DispContent}`;
    },
    ModalDispContent() {
      return this.$store.state.ModalDispContent;
    },
    UserName() {
      return this.$store.state.UserName;
    },
    IsMobile() {
      return this.$store.state.IsMobile;
    },
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * 検索キー設定
     * @param {string} searchKey 検索キー
     */
    setSearchData(searchKey) {
      this.searchKey = searchKey;
    },

    /**
     * コンテンツツリー再読み込み
     */
    async reloadContent() {

      const response = await apiMenuGet();
      // コンテンツツリーに設定
      this.$store.commit("setContentTree", response.DisplayContent);
      this.$store.commit("setDispContent", this.$store.state.DispContent);
    },

    /**
     * コンテンツ初期化
     */
    async initContent() {
      // URL上のページ遷移先を取得
      let id = null;
      const query = Object.assign({}, this.$route.query);
      if (query.id != null) {
        id = query.id;
        delete query.id;
        this.$router.push({ query: query });
      }

      if (this.$store.state.ContentTree == null) {
        const filetree = await apiMenuGet();

        // コンテンツツリーに設定
        this.$store.commit("setContentTree", filetree.DisplayContent);
        if (id == null) {
          // ページ遷移指定が無ければTopページを表示
          id = filetree.DisplayContent[0].id;
        }
      }

      if (id != null) {
        /** @type {import("@/helpers/type").ContentData[]} */
        const tree = this.$store.state.ContentTree;
        if (null != ContentHelper.serachContent(tree, id)) {
          // 存在するIDの場合は、そのページを表示
          this.$store.commit("setDispContent", id);
        } else {
          // 存在しないページの場合は、Topページを表示
          this.$store.commit("setDispContent", tree[0].id);
        }
      }

      LogHelper.postLog(LogHelper.log.init, null, null, this.$store.state.DispContent);
    },

    /**
     * ダミー処理
     */
    dmy() {
      // DO NOTHING
    },

    /**
     * コンテンツ設定
     * @param {string} data 選択コンテンツ
     */
    selectMethod(data) {
      /** @type {HTMLElement} */
      const modal = this.$refs["ModalClose"];
      // 遷移時にモーダル表示を閉じる
      modal.click();

      /** @type {HTMLElement | undefined} */
      const sideMenu = this.$refs["SideMenuClose"];
      // 遷移時にサイドメニューを閉じる
      sideMenu?.click();

      const content = ContentHelper.serachContent(this.$store.state.ContentTree, data);
      const source = this.$store.state.DispContent;

      this.$store.commit("setDispContent", data);

      LogHelper.postLog(LogHelper.log.transition, content.name, source, data);
    },
    /**
     * 表示履歴 戻る
     */
    selectHistoryBeforeMethod() {
      const source = this.$store.state.DispContent;

      this.$store.commit("setContentHistoryBefore");

      const destination = this.$store.state.DispContent;
      LogHelper.postLog(LogHelper.log.transition, "戻る", source, destination);
    },
    /**
     * 表示履歴 進む
     */
    selectHistoryPreviousMethod() {
      const source = this.$store.state.DispContent;

      this.$store.commit("setContentHistoryPrevious");

      const destination = this.$store.state.DispContent;
      LogHelper.postLog(LogHelper.log.transition, "進む", source, destination);
    },

    /**
     * コンテンツ設定（モーダル）
     * @param {string} data 選択コンテンツ
     * @param {string} toggleModal トグルモーダル
     */
    selectModalMethod(data, toggleModal) {
      const content = ContentHelper.serachContent(this.$store.state.ContentTree, data);
      this.isPatientDataModal = (content.type == "item-PatientData");
      this.toggleModal = toggleModal ?? "";
      this.$store.commit("setModalDispContent", data);
    },

    /**
     * コンテンツ設定（モーダル→メニュー）
     */
    onModalRelayClick() {
      this.toggleModal = "";

      const source = this.$store.state.DispContent;
      const content = ContentHelper.serachContent(this.$store.state.ContentTree, this.$store.state.ModalDispContent);

      this.$store.commit("setDispContent", this.$store.state.ModalDispContent);

      const destination = this.$store.state.DispContent;
      LogHelper.postLog(LogHelper.log.transition, content.name, source, destination);
    },

    /**
     * コンテンツ設定（モーダル→メニュー）
     * 全ての動画ページへ移動
     */
    onModalPatientListRelayClick() {
      this.toggleModal = "";

      /** 全ての動画ページのコンテンツID */
      const contentId = "161";

      const source = this.$store.state.DispContent;
      const content = ContentHelper.serachContent(this.$store.state.ContentTree, contentId);

      this.$store.commit("setDispContent", contentId);

      const destination = this.$store.state.DispContent;
      LogHelper.postLog(LogHelper.log.transition, content.name, source, destination);
    },

    /**
     * ブックマーク削除
     */
    async delBookmark() {
      await apiBookmarkDelete({ id: this.menuSelectValue.id });
      this.reloadContent();
    },
    /**
     * 右クリックメニュー表示
     * @param {ContentData} value 選択コンテンツ
     * @param {{pageX: number; pageY: number;}} e 右クリックイベント情報
     */
    onRightclick(value, e) {
      this.menuSelectValue = value;
      this.menu_position = {
        top: `${e.pageY}px`,
        left: `${e.pageX}px`,
      };

      this.isMenuEnable = value.isBookmark == true;
      this.isMenuShow = true;
    },
    /**
     * 左クリック時のメニュー非表示
     */
    onLeftclick() {
      this.isMenuShow = false;
    },

    /** メニューオプション更新 */
    menuOptionsRefresh() {

      /** @type {InstanceType<SideMenu>} */
      const menuPC = this.$refs["SideMenuPC"];
      /** @type {InstanceType<SideMenu>} */
      const menuMobile = this.$refs["SideMenuMobile"];
      menuPC?.refresh();
      menuMobile?.refresh();
    },
  },
});
</script>

<!-----------------------------------
  スタイル
---->
<style scoped>
#MasterPage {
  width: 100vw;
  min-height: 100vh;
}

@supports (min-height: 100dvh) {
  #MasterPage {
    width: 100dvw;
    min-height: 100dvh;
  }
}

.ContextMenu {
  position: absolute;
  z-index: 2;
}

/* フッター */
#footer {
  height: var(--ddr-footer-height);
  background-color: var(--ddr-footer-background);
}

#footer div {
  color: white;
  font-size: 12px;
}

/* ハンバーガーメニューのヘッダ */
.sidemenu-header {
  height: var(--ddr-content-header-height);
}

/* テキストダイアログ幅 */
.text-modal-dialog {
  max-width: min(800px, calc(100% - 1rem));
}

/* 患者データダイアログ幅 */
.data-modal-dialog {
  max-width: min(1140px, calc(100% - 1rem));
}
</style>
