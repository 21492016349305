import axios from "axios";
import { Auth } from "aws-amplify";
import { IsLocal } from "@/helpers/IsLocal";

// /atlas/api/xxxにパス変更が行える
// axios.defaults.baseURL = "/Atlas/";

/** 通信のリトライ回数 */
const RetryCount = 3;
/** リトライの待機時間(ms) */
const RetryInterval = 1000;

const customAxios = axios.create();

/** CognitoのIDトークン */
let idToken = "";
/** IDトークンをリセットする */
function resetIdToken() {
  idToken = "";
}

/**
 * APIのURLを取得する
 * @param {string} api api
 * @returns url
 */
function getApiUrl(api) {
  const host = window.location.host;

  if (!IsLocal) {
    // AWS用
    const domain = `https://${host}`;
    return `${domain}/api/atlas/${api}`;
  }
  else {
    // ローカルホスト用(開発環境のAPI Gateway)
    const domain = "https://5yipxvv6bg.execute-api.ap-northeast-1.amazonaws.com";
    return `${domain}/dev/atlas/${api}`;
  }
}

/**
 * 一定時間待機する
 * @param {number} ms 待機時間
 * @returns 
 */
async function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * APIの待ち時間
 * @param {number} rps 
 * @returns 
 */
async function apiWait(rps) {
  await delay(1500 / rps);
}

/**
 * 通信リトライ
 * @param {function(): Promise<import("axios").AxiosResponse<any, any>>} ms 待機時間
 * @returns 
 */
async function retry(request) {
  let lastError = new Error("API実行に失敗しました");
  for (let retry = 0; retry < RetryCount; retry++) {
    try {
      return await request();
    } catch (error) {
      lastError = error;
      if (axios.isAxiosError(error)) {
        const axiosError = error;
        switch (axiosError.status) {
          case 401: // 認証エラー
            // 認証情報を再取得してリトライ
            idToken = null;
            await setAuthorizationHeader();
            continue;

          case 429: // Lambdaスロットリングエラー
            await delay(RetryInterval * (retry + 1));
            continue;

          default: throw error;
        }
      }
    }
  }
  throw lastError;
}

/**
 * 認証用のヘッダを設定する
 */
async function setAuthorizationHeader() {
  // IDトークンを取得する
  /** @type {import("aws-amplify").Auth.identity} */
  const user = await Auth.currentAuthenticatedUser();
  idToken = user.signInUserSession.idToken.jwtToken;

  axios.defaults.headers.common["Authorization"] = idToken;
  customAxios.defaults.headers.common["Authorization"] = idToken;
}

/**
 * GETメソッド
 * @param {string} url 
 * @param {?import("axios").AxiosRequestConfig<any>} config 
 * @returns 
 */
async function getMethod(url, config) {
  return await retry(async () => {
    await setAuthorizationHeader();
    return await axios.get(url, config);
  });
}
customAxios.get = getMethod;

/**
 * POSTメソッド
 * @param {string} url 
 * @param {?any} data 
 * @param {?import("axios").AxiosRequestConfig<any>} config 
 * @returns 
 */
async function postMethod(url, data, config) {
  return await retry(async () => {
    await setAuthorizationHeader();
    return await axios.post(url, data, config);
  });
}
customAxios.post = postMethod;

/**
 * PUTメソッド
 * @param {string} url 
 * @param {?any} data 
 * @param {?import("axios").AxiosRequestConfig<any>} config 
 * @returns 
 */
async function putMethod(url, data, config) {
  return await retry(async () => {
    await setAuthorizationHeader();
    return await axios.put(url, data, config);
  });
}
customAxios.put = putMethod;

/**
 * DELETEメソッド
 * @param {string} url 
 * @param {?import("axios").AxiosRequestConfig<any>} config 
 * @returns 
 */
async function deleteMethod(url, config) {
  return await retry(async () => {
    await setAuthorizationHeader();
    return await axios.delete(url, config);
  });
}
customAxios.delete = deleteMethod;

export default customAxios;

export { getApiUrl, delay, apiWait, resetIdToken };
