<!-----------------------------------
  ユーザー更新チェック
  ログインユーザーが切り替わっていないか確認する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div></div>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { Auth } from "aws-amplify";

/**
 * @template T
 * @typedef {import("axios").AxiosResponse<T>} AxiosResponse
 */
/**
 * @typedef {{
 *   userid: ?string;
 *   username: ?string;
 * }} GetUserInfo
 * @typedef {{
 *   Status: number;
 *   Challenge: ?string;
 *   Redirect: boolean;
 *   Token: string;
 * }} GetLoginStatus
 */

export default defineComponent({
  name: "UserWatch",

  setup() {
    const store = useStore();

    onBeforeMount(async () => {
      try {
        // ログイン状態を確認し、ユーザーIDを設定する
        const user = await Auth.currentAuthenticatedUser();
        store.commit("setUserName", user.attributes?.name);
        store.commit("setUserId", user.username);
      } catch (error) {
        // ユーザーIDを削除する
        store.commit("setUserId", null);
      }
    });

    const checkUserFunc = async () => {
      const message = "アカウントからサインアウトしています。\nもう一度サインインするには[OK]を押してください。";

      try {
        const user = await Auth.currentAuthenticatedUser();
        if (store.state.UserId != null && store.state.UserId != user.username) {
          // 異なるユーザーの場合は、セッションをリセットしてリロード
          store.commit("logout");
          alert(message);
          location.reload();
        }
      } catch (error) {
        if (store.state.UserId != null) {
          // 認証エラーの場合は、セッションをリセットしてリロード
          store.commit("logout");
          alert(message);
          location.reload();
        }
      }
    };

    onMounted(() => {
      window.addEventListener("focus", checkUserFunc);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("focus", checkUserFunc);
    });
    return {};
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {},
});
</script>
