/**
 * コンテンツ表示で使用する定数
 */
export default {
  /** PC<=>モバイル表示切替幅（px） */
  mobileWidth: 300 + 576,
  /** 患者データ表示切替幅（px） */
  patientDataWidth: 300 + 660,
  
  /** DDRAtlasのルートパス */
  path: process.env.BASE_URL,

  /** Member Siteドメイン */
  msDomain: process.env.VUE_APP_MS_DOMAIN,
};
