import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "video.js/dist/video-js.min.css";

import { Amplify } from "aws-amplify"
import { Auth } from "@/amplifyConfig";
import "@aws-amplify/ui-vue/styles.css";

Amplify.configure({ Auth: Auth });

import "/public/Content/Doc.css";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(PerfectScrollbar);
app.mount("#app");
