<!-----------------------------------
  患者データの呼吸検査の表を表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div>
    <h2>呼吸機能検査</h2>
    <table class="table table01 mb-1">
      <thead>
        <tr>
          <th v-for="(val, index) in header" :key="index">{{ val }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowNum) in body" :key="rowNum">
          <td v-for="(col, colNum) in row" :key="colNum">
            {{ dataFormat(col, colNum) }}
          </td>
        </tr>
      </tbody>
    </table>
    <br>

    <figure>
      <img style="margin: auto;width: 50%;" v-bind:src="fvf" alt="フローボリューム曲線">
      <figcaption>フローボリューム曲線</figcaption>
    </figure>
  </div>
</template>
<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "PatientDataPFT",

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    /** @type {{new(): string[];}} */
    pftHeader: Object,
    /** @type {{new(): (number | null)[][];}} */
    pftBody: Object,
    fvFile: String,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup(props) {
    const header = computed(() => {
      return props.pftHeader ?? [];
    });
    const body = computed(() => {
      return props.pftBody ?? [];
    });
    const fvf = computed(() => {
       return props.fvFile ?? "";
    });

    return { header, body, fvf };
  },

  methods: {
    /**
     * 表示データフォーマット
     * @param {number | null} value 数値
     * @param {number} index 列数
     * @returns {string} 画面表示値
     */
    dataFormat(value, index) {
      if (value == null || isNaN(value)) {
        return "-";
      }
      if (this.header[index].includes("%")) {
        return value.toFixed(1) + "%";
      } else {
        return value.toFixed(1);
      }
    },
  },
});
</script>

<style scoped>
thead tr th {
  border-bottom: none;
}
</style>
