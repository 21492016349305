<!-----------------------------------
  アプリケーション
  全画面の既定要素、index.htmlに紐付けを行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div v-bind:class="{ mobile: isMobile, pc: !isMobile }" @click.right.prevent="">
    <MaintenancePageVue v-if="$store.state.IsMaintenance" :startDate="$store.state.MaintenanceStart"
      :endDate="$store.state.MaintenanceEnd" />
    <router-view v-else />
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, onBeforeMount, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { Auth } from "aws-amplify";
import ContentDefine from "@/helpers/ContentDefine";
import MaintenancePageVue from "@/components/Utility/MaintenancePage.vue";
import { setMaintenanceCallback, apiMaintenanceGet } from "@/helpers/ApiHelper";

export default defineComponent({
  name: "App",

  components: {
    MaintenancePageVue,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup() {
    const isMobileMethod = function () {
      return window.innerWidth < ContentDefine.mobileWidth;
    };

    // 横幅検知処理
    const store = useStore();
    const isMobile = ref(isMobileMethod());
    store.commit("setIsMobile", isMobile.value);

    const resize = () => {
      isMobile.value = isMobileMethod();
      store.commit("setIsMobile", isMobile.value);
    };
    onMounted(() => {
      window.addEventListener("resize", resize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize);
    });

    onMounted(() => {
      // 初期表示時、メンテナンス情報を取得する
      setMaintenanceCallback((res) => {
        store.commit("setIsMaintenance", {
          IsMaintenance: res.IsMaintenance,
          Start: res.Start,
          End: res.End,
        });
      });
      apiMaintenanceGet();
    });

    // Hosted UIのログイン処理
    onBeforeMount(() => {
      Auth.currentSession()
        .then(() => {
          if (localStorage.getItem("redirect") === "atlogin") {
            localStorage.setItem("redirect", "");
            location.href = `${ContentDefine.msDomain}/healthcare/ddrms/members/login.html`;
          }
        })
        .catch(() => {
          // ログインしていなければ強制遷移
          localStorage.setItem("redirect", "atlogin");
          Auth.federatedSignIn();
        });
    });

    return { isMobile };
  },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style>
:root {
  /* ヘッダ */
  --ddr-header-height: 50px;
  --ddr-header-background: rgb(53, 56, 61);

  /* フッタ */
  --ddr-footer-height: 20px;
  --ddr-footer-background: #333333;

  /* コンテンツヘッダ */
  --ddr-content-header-height: 50px;
  --ddr-content-header-background: rgb(217, 217, 217);
  /* コンテンツ */
  --ddr-content-height: calc(100vh - var(--ddr-header-height) - var(--ddr-footer-height) - var(--ddr-content-header-height));
  /* サイドメニュー */
  --ddr-sidemenu-width: 300px;
  --ddr-sidemenu-background: rgb(53, 56, 61);
}

@supports (min-height: 100dvh) {
  :root {
    /* コンテンツ */
    --ddr-content-height: calc(100dvh - var(--ddr-header-height) - var(--ddr-footer-height) - var(--ddr-content-header-height));
  }
}
</style>
