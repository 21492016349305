
<!-----------------------------------
    コンテンツビューア
    映像画像テキストのコンテンツ表示の実施を行う
----------------------------------->

<!-----------------------------------
    テンプレート
----------------------------------->
<template>
  <div class="ContentViewer bg-white" v-bind:class="{ 'modal-display': isModal }">
    <div class="NoContent" v-if="!dispContent"></div>

    <div v-if="dispContent" class="item">
      <!--テキスト-->
      <div class="item-TextBook" v-if="dispContent.type == 'item-TextBook'">
        <!--テキストコンテンツ-->
        <div>
          <perfect-scrollbar v-on:ps-y-reach-end="scrollEndLog">
            <div v-if="!isModal" id="head-top"></div>
            <div v-else id="head-top-modal"></div>
            <div v-html="htmlDoc" class="TextContent-width" ref="HtmlContent" />
          </perfect-scrollbar>
          <ScrollTopVue :isModal="isModal" :contentId="content" />
        </div>
      </div>

      <!--患者リスト-->
      <div class="item-PatientList" v-if="dispContent.type == 'item-PatientList'">
        <PatientListViewerVue :listURL="dispContent.url" @selectMethod="selectMethod" />
      </div>

      <!--患者データ-->
      <div class="item-PatientData" v-if="dispContent.type == 'item-PatientData'">
        <PatientDataViewerVue :content="content" :searchKey="searchKey" :isModal="isModal" />
      </div>

      <!--論文リスト-->
      <div class="item-PaperList" v-if="dispContent.type == 'item-PaperList'">
        <PaperListViewerVue :listURL="dispContent.url" :searchKey="searchKey" />
      </div>

      <!--お知らせ-->
      <div class="item-Notice" v-if="dispContent.type == 'item-Notice'">
        <NoticeViewerVue :searchKey="searchKey" @selectMethod="selectMethod" @selectModalMethod="selectModalMethod" @menuOptionsRefresh="menuOptionsRefresh" />
      </div>
    </div>
  </div>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, nextTick } from "vue";
import PatientListViewerVue from "@/components/Content/PatientListViewer.vue";
import PatientDataViewerVue from "@/components/Content/PatientDataViewer.vue";
import PaperListViewerVue from "@/components/Content/PaperListViewer.vue";
import NoticeViewerVue from "@/components/Content/NoticeViewer.vue";
import ScrollTopVue from "@/components/Content/ScrollTop.vue";
import ContentHelper from "@/helpers/ContentHelper";
import LogHelper from "@/helpers/LogHelper";

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 */

export default defineComponent({
  name: "ContentViewer",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    PatientListViewerVue,
    PatientDataViewerVue,
    PaperListViewerVue,
    NoticeViewerVue,
    ScrollTopVue,
  },

  //*****************************
  // 継承イベント定義
  //*****************************
  emits: {
    /** @type {function(string):void} */
    selectMethod: null,
    /** @type {function(string,string):void} */
    selectModalMethod: null,
    /** @type {function():void} */
    menuOptionsRefresh: null,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    content: String,
    searchKey: String,
    isModal: Boolean,
  },
  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      htmlContent: "",
      /** @type {ContentData} */
      dispContent: { name: "" },
      scrollEndFlag: false,

      videojs: Object.freeze({ instance: new ContentHelper.VideoJs() }),
    };
  },

  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    // html表示項目
    htmlDoc() {
      let doc = this.htmlContent;

      if (this.videojs.instance) this.videojs.instance.reset();
      doc = ContentHelper.searchHighlight(doc, this.searchKey);
      nextTick(() => {
        LogHelper.setOnclickEvent(
          this.content,
          this.$refs["HtmlContent"],
          this.selectMethod,
          this.selectModalMethod
        );

        this.videojs.instance.set(this.$refs["HtmlContent"]);
      });
      return doc;
    },
  },
  //*****************************
  // 表示後の実行処理
  //*****************************
  mounted() {
    this.loadContent();
  },
  //*****************************
  // 表示終了後の実行処理
  //*****************************
  beforeUnmount() {
    this.videojs.instance.reset();
  },

  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // 選択コンテンツ
    content: {
      handler: function () {
        this.loadContent();
      },
    },
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * コンテンツ読み込み
     */
    loadContent() {
      console.debug("id:" + this.content);
      this.dispContent = this.serachContent(
        this.$store.state.ContentTree,
        this.content
      );

      if (this.dispContent == null) {
        // 選択コンテンツがからの場合は何も表示しない
        this.dispContent = { name: "" };
      } else {
        if (this.dispContent.type == "item-TextBook") {
          // テキストコンテンツを取得し、表示に反映する
          this.videojs.instance.reset();
          this.htmlContent = "";
          this.scrollEndFlag = false;
          const getUrl = `${this.dispContent.url}/${this.dispContent.text}`;
          ContentHelper.getTextContent(getUrl).then((res) => {
            // 取得中に表示コンテンツを切り替えていないか確認する
            const nowUrl = `${this.dispContent.url}/${this.dispContent.text}`;
            if (getUrl == nowUrl) {
              this.htmlContent = res.data;
            }
          });
        }
      }
    },

    /**
     * ページ遷移処理
     * @param {string} value 選択コンテンツ
     */
    selectMethod(value) {
      this.$emit("selectMethod", value);
    },

    /**
     * モーダル表示処理
     * @param {string} value 選択コンテンツ
     * @param {string} toggleModal トグルモーダル
     */
    selectModalMethod(value, toggleModal) {
      this.$emit("selectModalMethod", value, toggleModal);
    },

    /** メニューオプション更新 */
    menuOptionsRefresh() {
      this.$emit("menuOptionsRefresh");
    },

    /** コンテンツIDから対象コンテンツデータを取得する */
    serachContent: ContentHelper.serachContent,

    /**
     * スクロール完了ログ送信処理
     */
    scrollEndLog() {
      // コンテンツ切替中はログ送信を行わない
      if (this.htmlDoc != "") {
        // 一度送信した後は再送信しない
        if (!this.scrollEndFlag) {
          LogHelper.postLog(
            LogHelper.log.scroll_end,
            this.dispContent.name,
            this.content,
            this.content
          );
          this.scrollEndFlag = true;
        }
      }
    },
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
/* 通常時のサイズ設定 */
.pc .item .ps {
  width: calc(100vw - var(--ddr-sidemenu-width));
  height: var(--ddr-content-height);
}

/* モバイル（サイドメニューなし）のサイズ設定 */
.mobile .item .ps {
  width: 100vw;
  height: var(--ddr-content-height);
}

/* モーダル表示のサイズ設定 */
.modal-display .item .ps {
  width: 100%;
  /* 縦幅 - ダイアログ余白 - モーダルヘッダ縦幅 - ボーダーライン - ヘッダ縦幅*/
  height: calc(100vh - 3.5rem - 71px - 2px - var(--ddr-content-header-height));
}
</style>
