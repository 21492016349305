<!-----------------------------------
  基本画面
  ログイン画面、コンテンツ表示画面を提供する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <template v-if="kolAccess == true">
    <UserWatchVue />
    <MasterPageVue v-if="$store.state.UserId != null && cookieTimeId != null" />
  </template>
  <template v-else-if="kolAccess == false">
    <KOLBlockPageVue />
  </template>
  <template v-else>
    <!-- 判定中は白ページを表示 -->
  </template>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import UserWatchVue from "@/components/Utility/UserWatch.vue";
import MasterPageVue from "@/components/MasterPage.vue";
import KOLBlockPageVue from "@/components/Utility/KOLBlockPage.vue";
import LogHelper from "@/helpers/LogHelper";
import { apiUserinfoGet } from "@/helpers/ApiHelper";

export default defineComponent({
  name: "HomeView",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    UserWatchVue,
    MasterPageVue,
    KOLBlockPageVue,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup() {
    const postLog = () => {
      // ログイン中のみ終了ログを記録
      LogHelper.postLog(LogHelper.log.exit);

      // 確認ダイアログを出さない場合、タブ／ウィンドウ終了時のログが記録できない
      // リロード／別ドメインへの移動は記録可能
      //event.returnValue = "確認";
      //return event.returnValue;
    };

    /** @type {import("vue").Ref<boolean>} */
    const kolAccess = ref();
    const cookieTimeId = ref();
    const getResourceCookie = async () => {
      // 5時間毎に認証用のCookieを再取得する
      const res = await apiUserinfoGet();
      kolAccess.value = res.access;

      cookieTimeId.value = setTimeout(getResourceCookie, 1000 * 60 * 60 * 5);
    };
    getResourceCookie();

    // ログ送信イベントの登録
    onMounted(() => {
      window.addEventListener("beforeunload", postLog);
    });
    // ログ送信イベントの登録
    onUnmounted(() => {
      window.removeEventListener("beforeunload", postLog);
      clearTimeout(cookieTimeId.value);
    });

    return { kolAccess, cookieTimeId };
  },
  data() { return {}; },
});
</script>
