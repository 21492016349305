
<!-----------------------------------
  コンテンツヘッダ
  コンテンツのヘッダ部を表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="ContentHeader">
    <!-- タイトルナビゲーション -->
    <nav class="navbar title">
      <div class="container-fluid justify-content-start gap-3">
        <!-- サイドメニュー表示 -->
        <!-- サイドメニュー非表示 且 モーダル表示でなければ表示 -->
        <img v-if="IsMobile && !isModal" src="../assets/memu-icon.svg" alt="SideMenu" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSideMenu" aria-controls="offcanvasSideMenu" />

        <!-- モーダル表示の場合は戻る進むを表示しない -->
        <span>
          <img v-if="!isModal" class="history me-2" :class="{ disable: !hasHistoryBefore }" src="../assets/arrow-left.svg"
            alt="History" @click="selectHistoryBeforeMethod" />
          <img v-if="!isModal" class="history" :class="{ disable: !hasHistoryPrevious }" src="../assets/arrow-right.svg"
            alt="History" @click="selectHistoryPreviousMethod" />
        </span>
        <span class="navbar-brand py-0 mb-0 me-0">{{ contentName }}</span>
        <img v-if="dispContent?.isBookmark" src="../assets/star-fill.svg" class="bookmark" alt="Bookmark"
          @click="SetBookmark" />
        <img v-else src="../assets/star-void.svg" class="bookmark" alt="Bookmark" @click="SetBookmark" />
      </div>
    </nav>
  </div>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent } from "vue";
import ContentHelper from "@/helpers/ContentHelper";
import axios from "@/helpers/AxiosHelper";
import { apiBookmarkPost, apiBookmarkDelete } from "@/helpers/ApiHelper";

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 */

export default defineComponent({
  name: "ContentHeader",

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    content: String,
    isModal: Boolean,
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /** @type {ContentData} */
      dispContent: { name: "" },
      patientName: "",
    };
  },
  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    // コンテンツヘッダ名
    contentName() {
      if (this.dispContent) {
        if (this.dispContent.header) {
          // config.jsonのheaderの値を表示
          return this.dispContent.header;
        } else if (this.dispContent.name) {
          if (this.dispContent.type == "item-PatientData") {
            // 患者データの値を表示
            return this.patientName;
          } else {
            // config.jsonのnameの値を表示
            return this.dispContent.name;
          }
        }
      }

      return "";
    },
    // 履歴（戻る）有無
    hasHistoryBefore() {
      return this.$store.state.ContentHistoryBefore.length != 0;
    },
    // 履歴（進む）有無
    hasHistoryPrevious() {
      return this.$store.state.ContentHistoryPrevious.length != 0;
    },
    IsMobile() {
      return this.$store.state.IsMobile;
    },
  },
  //*****************************
  // 表示後の実行処理
  //*****************************
  mounted: function () {
    this.$nextTick(function () {
      this.loadContent();
    });
  },
  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // 選択コンテンツ
    content: {
      handler: function () {
        this.loadContent();
      },
    },
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * コンテンツ読み込み
     */
    loadContent() {
      this.dispContent = this.serachContent(
        this.$store.state.ContentTree,
        this.content
      );

      if (this.dispContent?.type == "item-PatientData") {
        // 患者データの場合は、患者情報を読取
        axios
          .get(
            encodeURI(
              `${this.dispContent.url}/Data_${this.dispContent.patientId}.json`
            )
          )
          .then((res) => {
            if (res.data != null) {
              /**
               * @type {{
               *   patientId: string;
               *   age: number;
               *   gender: "M" | "F";
               *   BMI: number;
               *   height: number;
               *   disease: string[];
               * }}
               */
              const data = res.data;

              const age = data.age;
              const gender = ContentHelper.replaceGender(data.gender);
              const disease = ContentHelper.replaceDisease(data.disease);

              this.patientName = `${age}歳、${gender}、${disease}`;
            } else {
              this.patientName = "";
            }
          })
          .catch((error) => {
            console.error(error);
            this.patientName = "";
          });
      }
    },

    /**
     * ページ遷移 表示履歴 戻る
     */
    selectHistoryBeforeMethod() {
      this.$emit("selectHistoryBeforeMethod");
    },
    /**
     * ページ遷移 表示履歴 進む
     */
    selectHistoryPreviousMethod() {
      this.$emit("selectHistoryPreviousMethod");
    },

    /**
     * ブックマーク登録／解除
     */
    async SetBookmark() {
      const content = this.serachContent(
        this.$store.state.ContentTree,
        this.content
      );

      if (content.isBookmark != true) {
        await apiBookmarkPost({ Id: content.id });
        // コンテンツツリーに設定
        this.$emit("reloadContent");
        this.dispContent.isBookmark = true;
      } else {
        await apiBookmarkDelete({ id: content.id });
        // コンテンツツリーに設定
        this.$emit("reloadContent");
        this.dispContent.isBookmark = false;
      }
    },

    /** コンテンツIDから対象コンテンツデータを取得する */
    serachContent: ContentHelper.serachContent,
  },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
/* タイトルヘッダ */
.title {
  height: var(--ddr-content-header-height);
  background-color: var(--ddr-content-header-background);
}

.title * {
  color: black;
}

.title img {
  height: 26px;
  cursor: pointer;
}

.title img.history {
  height: 20px;
  filter: brightness(40%);
}

.title img.history:hover {
  filter: brightness(60%);
}

.title img.history.disable {
  filter: brightness(80%);
}

.title img.bookmark {
  filter: brightness(200%);
}

.title img.bookmark:hover {
  filter: brightness(120%);
}
</style>
