
<!-----------------------------------
  論文リストビューア
  論文リストの一覧表示の実施を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="py-1">
    <div class="d-flex flex-column paper-size" ref="TableClient">
      <!-- 検索部 -->
      <div class="w-100 row g-3 align-items-center mb-3">
        <div class="col-auto">
          <button class="btn btn-primary btn-sm" :disabled="!hasFilter" @click="onResetFilter()">フィルタ解除</button>
        </div>
        <div class="col-auto">{{ visibleCount }}件</div>
      </div>

      <!-- テーブル部 -->
      <perfect-scrollbar class="flex-grow-1" ref="TextContentScroll">
        <div ref="HtmlContent">
          <TanstackTableVue v-if="0 < this.columns.length" class="paper-table" :itemSource="paperList" :columns="columns"
            v-slot="{ cell }" tag="PaperListViewer" ref="PaperTable">
            <!-- urlの場合はリンク表示 -->
            <a v-if="cell.column.id == 'url'" v-bind:href="cell.row.original[cell.column.columnDef.accessorKey]"
              target="_blank" rel="noopener noreferrer" aria-label="paper link"
              @click.left="onLinkClick(cell.row.original[cell.column.columnDef.accessorKey])"
              @click.middle="onLinkClick(cell.row.original[cell.column.columnDef.accessorKey])">
              <span v-html="searchHighlight(cell.row.original[cell.column.columnDef.accessorKey], searchKey)"></span>
            </a>
            <template v-else-if="cell.column.id == 'title'">
              <template v-if="!!cell.row.original['annotation']">
                <span class="annotation">{{ cell.row.original['annotation'] }}</span><br />
              </template>
              <span v-html="searchHighlight(cell.row.original[cell.column.id], searchKey)"></span>
            </template>
            <span v-else v-html="searchHighlight(cell.row.original[cell.column.id], searchKey)"></span>
          </TanstackTableVue>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import TanstackTableVue from "@/components/Utility/TanstackTable.vue";
import TanstackTableFilterVue from "@/components/Utility/TanstackTableFilter.vue";
import { createColumnHelper, filterFns } from "@tanstack/vue-table";
import ContentHelper from "@/helpers/ContentHelper";
import LogHelper from "@/helpers/LogHelper";
import customAxios from "@/helpers/AxiosHelper";

export default defineComponent({
  name: "PaperListViewer",

  components: {
    TanstackTableVue,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    contentId: String,
    listURL: String,
    searchKey: String,
  },

  setup() {

    // テキストコンテンツ用のリサイズ検知
    /** @type {import("vue").Ref<HTMLElement>} */
    const HtmlContent = ref();
    /** @type {import("vue").Ref<import("perfect-scrollbar").default>} */
    const TextContentScroll = ref();
    /** @type {ResizeObserver} */
    let contentObserver;
    onMounted(() => {
      contentObserver = new ResizeObserver(() => {
        TextContentScroll.value?.update();
      });
      contentObserver.observe(HtmlContent.value);
    });
    onBeforeUnmount(() => {
      contentObserver?.disconnect();
    });

    return {
      HtmlContent,
      TextContentScroll,
    };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /** @type {import("@tanstack/vue-table").ColumnDef<ListFinderUser, any>[]} */
      columns: [],
      paperList: [],
      /** @type {import("@tanstack/vue-table").TableOptions<any>} */
      options: null,

      hasFilter: false,
      visibleCount: 0,
      filterInterval: null,
    };
  },
  //*****************************
  // 初期化処理
  //*****************************
  created() {
    this.fetchListUrl(this.listURL);
  },
  mounted() {
    // フィルタ状態を監視する
    this.filterInterval = setInterval(this.watchTable, 100);
  },
  //*****************************
  // 終了処理
  //*****************************
  beforeUnmount() {
    clearInterval(this.filterInterval);
  },
  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // listファイル
    listURL: {
      handler: function () {
        this.fetchListUrl(this.listURL);
      },
    },
  },
  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * Paper.json取得処理
     * @param {string} url jsonファイルのURL
     */
    async fetchListUrl(url) {
      try {
        const res = await customAxios.get(encodeURI(url));
        /**
         * @type {{
         *  header: [{
         *    text: string;
         *    value: string;
         *    width: string;
         *    filter: {text:?boolean;check:?boolean;}
         *  }];
         *  data: [{[key:string]:string}]
         * }}
         */
        const json = res.data;

        /** @type {HTMLElement} */
        const tableClient = this.$refs["TableClient"];
        const width = tableClient.clientWidth - (9.6 * 2);

        // カラム定義
        /** @type {import("@tanstack/vue-table").ColumnHelper<ListFinderUser>} */
        const columnHelper = createColumnHelper();
        /** @type {import("@tanstack/vue-table").ColumnDef<ListFinderUser,any>[]} */
        const columns = [];
        for (const header of json.header) {
          const per = Number(header.width.slice(0, -1)) / 100;
          if (per <= 0) {
            // 幅が0%の場合は表示しない
            continue;
          }

          const options = {
            text: header.filter?.text ?? false,
            check: header.filter?.check ?? false
          };
          let filter = filterFns.includesString;
          if (options.check) {
            filter = filterFns.arrIncludesSome;
          }

          columns.push(
            columnHelper.accessor(header.value, {
              header: (headerContext) => {
                return <TanstackTableFilterVue
                  text={header.text}
                  headerContext={headerContext}
                  dataSource={this.paperList}
                  options={options}
                  enableFilter={this.hasFilter}
                ></TanstackTableFilterVue>;
              },
              accessorFn: row => `${row[header.value]}`,
              cell: (info) => info.getValue(),
              size: Math.floor(width * per),
              filterFn: filter,
            })
          );
        }
        this.columns = columns;
        this.paperList = json.data.filter((v) => v["url"] != "");
      } catch (error) {
        this.columns = [];
        this.paperList = [];
        console.error(error);
      }
    },

    /**
     * 遷移ログの保存
     * @param {string} link 
     */
    onLinkClick(link) {
      LogHelper.postLog(LogHelper.log.transition, link, this.contentId, "");
    },

    /** テーブル監視 */
    watchTable() {
      /** @type {InstanceType<TanstackTableVue>} */
      const table = this.$refs["PaperTable"];
      if (table != null) {
        this.setHasFilter(table);
        this.setVisibleCount(table);
      }
    },
    /**
     * フィルタ状態設定
     * @param {InstanceType<TanstackTableVue>} table 
     */
    setHasFilter(table) {
      const columns = table.vueTable.getAllColumns();
      for (const col of columns) {
        if (col.getIsFiltered()) {
          this.hasFilter = true;
          return;
        }
      }
      this.hasFilter = false;
    },
    /**
     * 表示行数設定
     * @param {InstanceType<TanstackTableVue>} table 
     */
    setVisibleCount(table) {
      this.visibleCount = table.vueTable.getRowModel().rows.length;
    },

    /** フィルタリセット */
    onResetFilter() {
      /** @type {InstanceType<TanstackTableVue>} */
      const table = this.$refs["PaperTable"];
      table.vueTable.resetColumnFilters(false);
    },

    //================================
    // 検索ワード強調表示
    //================================
    searchHighlight: ContentHelper.searchHighlight,
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
.paper-size {
  /* 縦幅 - テーブル余白 */
  height: calc(var(--ddr-content-height) - 0.5rem);
}

.pc .paper-size {
  width: calc(100vw - var(--ddr-sidemenu-width));
}

.mobile .paper-size {
  width: 100vw;
}

.paper-size>div {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.paper-table {
  font-size: 12px;
  table-layout: fixed;
}

.paper-table .annotation {
  font-size: 11px;
  color: red;
  font-weight: bold;
}
</style>
