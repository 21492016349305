import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { resetIdToken } from "@/helpers/AxiosHelper"

export default createStore({
  plugins: [createPersistedState({ storage: window.sessionStorage })],

  state: {
    /** @type {?import("@/helpers/type").ContentData} */
    ContentTree: null,
    /** @type {?import("@/helpers/type").ContentData} */
    ContentTreeState: null,

    /** @type {?string} */
    DispContent: null,
    /** @type {?string} */
    ModalDispContent: null,

    /**
     * 画面表示するユーザー名
     * @type {?string}
     */
    UserName: null,
    /**
     * Cognitoの識別ID
     * @type {?string} 
     */
    UserId: null,

    /**
     * 戻る履歴スタック（←）
     * @type {string[]}
     */
    ContentHistoryBefore: [],
    /**
     * 進む履歴スタック（→）
     * @type {string[]}
     */
    ContentHistoryPrevious: [],

    /** モバイル表示 */
    IsMobile: false,
    /** メンテナンス表示 */
    IsMaintenance: false,
    /** メンテナンス開始時刻 */
    MaintenanceStart: null,
    /** メンテナンス終了時刻 */
    MaintenanceEnd: null,

    /**
     * ユーザー設定
     * @type {import("@/helpers/type").UserConfig} 
     */
    UserConfig: null,
    /**
     * ユーザー設定
     * @type {import("@/helpers/type").UserLocalConfig} 
     */
    UserLocalConfig: null,
  },
  getters: {
  },
  mutations: {
    setContentTree(state, ContentTree) {
      state.ContentTreeState = null;
      state.ContentTree = ContentTree;
    },
    setContentTreeState(state, ContentTreeState) {
      state.ContentTreeState = ContentTreeState;
    },

    /** コンテンツ切替 */
    setDispContent(state, contentId) {
      // 移動前のコンテンツを履歴スタックに追加する
      if (state.DispContent != null) {
        state.ContentHistoryBefore.push(state.DispContent);
      }
      // 切り替えた場合は、進む履歴をクリアする
      state.ContentHistoryPrevious.length = 0;

      state.DispContent = contentId;
    },
    setModalDispContent(state, ModalDispContent) {
      state.ModalDispContent = ModalDispContent;
    },

    setUserName(state, UserName) {
      state.UserName = UserName;
    },
    setUserId(state, UserId) {
      state.UserId = UserId;
    },

    /** 履歴戻るボタン押下 */
    setContentHistoryBefore(state) {
      // 戻るスタックから取り出し、取り出したコンテンツを表示する
      // 表示中のコンテンツを進むスタックに追加する
      if (0 < state.ContentHistoryBefore.length) {
        const contentId = state.ContentHistoryBefore.pop();
        state.ContentHistoryPrevious.push(state.DispContent);

        state.DispContent = contentId;
      }
    },
    /** 履歴進むボタン押下 */
    setContentHistoryPrevious(state) {
      // 進むスタックから取り出し、取り出したコンテンツを表示する
      // 表示中のコンテンツを戻るスタックに追加する
      if (0 < state.ContentHistoryPrevious.length) {
        const contentId = state.ContentHistoryPrevious.pop();
        state.ContentHistoryBefore.push(state.DispContent);

        state.DispContent = contentId;
      }
    },
    /** モバイル表示 */
    setIsMobile(state, flag) {
      state.IsMobile = flag;
    },
    /** メンテナンス表示 */
    setIsMaintenance(state, data) {
      state.IsMaintenance = data.IsMaintenance;
      state.MaintenanceStart = data.Start;
      state.MaintenanceEnd = data.End;
    },

    /** ユーザー設定 */
    setUserConfig(state, data) {
      if (state.UserConfig == null) {
        state.UserConfig = data;
      } else {
        for (const key in state.UserConfig) {
          if (data[key] != undefined) {
            state.UserConfig[key] = data[key];
          }
        }
      }
    },

    /**
     * ユーザー設定
     * @param {{key:string;value:any}} data 設定データ
     */
    setUserConfigKey(state, data) {
      console.debug(data);
      if (state.UserConfig == null) {
        state.UserConfig = {};
        state.UserConfig[data.key] = data.value;
      } else {
        state.UserConfig[data.key] = data.value;
      }
    },

    /**
     * ユーザーローカル設定
     * @param {{key:string;value:any}} data 設定データ
     */
    setUserLocalConfigKey(state, data) {
      console.debug(data);
      if (state.UserConfig == null) {
        state.UserConfig = {};
        state.UserConfig[data.key] = data.value;
      } else {
        state.UserConfig[data.key] = data.value;
      }
    },

    /** ログアウト */
    logout(state) {
      state.ContentTree = null;
      state.ContentTreeState = null;
      state.DispContent = null;
      state.ModalDispContent = null;
      state.UserName = null;
      state.UserId = null;
      state.ContentHistoryBefore = [];
      state.ContentHistoryPrevious = [];
      resetIdToken();
    },
  },
  actions: {
  },
  modules: {
  }
});
