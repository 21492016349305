import { apiLogPost } from "@/helpers/ApiHelper";
/**
 * ログ送信で使用する共通機能
 */

/** ログ種別 */
const logType = {
  /** 初期表示 */
  init: "init",
  /** ページ遷移 */
  transition: "transition",
  /** ページスクロール */
  scroll: "scroll",
  /** スクロール完了 */
  scroll_end: "scroll_end",
  /** モーダル表示 */
  modal: "modal",
  /** ボタン選択 */
  select: "select",
  /** 画面終了 */
  exit: "exit",
  /** ログアウト */
  logout: "logout",
};

/** タグ属性 */
const tagAttribute = {
  /** アンカーリンク */
  scroll: "data-ddr-scroll",
  /** 画面遷移 */
  href: "data-ddr-href",
  /** ページ移動（別サイト） */
  link: "data-ddr-link",
  /** モーダル表示 */
  modal: "data-ddr-modal",
};

export default {
  "log": logType,
  "postLog": postLog,
  "setOnclickEvent": setOnclickEvent,
};

/**
 * サーバーにアクセスログを送信する
 * @param {string} logType ログタイプ
 * @param {string} content 項目名
 * @param {string} source 遷移元ID
 * @param {string} destination 遷移先ID
 */
function postLog(logType, content, source, destination) {
  /** @type {import("@/helpers/ApiHelper").ApiLogPostRequest} */
  const body = {
    LogType: logType,
    Content: content,
    Source: source,
    Destination: destination,
  };

  if (postLogBuffer(body)) {
    apiLogPost(body);
    //console.debug(`${logType}:${content}:${source}:${destination}`);
  }
}

/** 送信ログ履歴 */
const logHistory = new Map();

/**
 * 同じ内容のログ送信を一定時間禁止する
 * @param {object} body リクエストボディ
 * @returns 送信可否
 */
function postLogBuffer(body) {
  const delTime = 1 * 1000;

  const nowTime = new Date().getTime();
  const str = JSON.stringify(body);

  // 禁止期限削除
  for (const [key, value] of logHistory) {
    const dif = nowTime - value;
    //console.debug(`${dif}:${key}`)
    if (delTime < dif) {
      logHistory.delete(key);
      //console.debug(`del:${key}`)
    }
  }

  // 禁止期限確認
  if (logHistory.get(str) == null) {
    logHistory.set(str, nowTime);
    return true;
  } else {
    return false;
  }
}

/**
 * テキストコンテンツにクリックイベントを設定する
 * @param {string} id 画面ID
 * @param {Element} docDom 対象コンテンツタグ
 * @param {selectMethod} selectMethod 画面遷移関数
 * @param {selectModalMethod} selectModalMethod モーダル表示関数
 */
function setOnclickEvent(id, docDom, selectMethod, selectModalMethod) {

  const postLog = (logType, content, destination) => {
    this.postLog(logType, content, id, destination);
  };
  // アンカーリンクにイベント追加
  docDom.querySelectorAll(`[${tagAttribute.scroll}]`).forEach((dom) => {
    const href = dom.getAttribute(tagAttribute.scroll);
    dom.setAttribute("href", href);

    dom.onclick = () => {
      postLog(logType.scroll, dom.innerText, id);
    };
  });

  // 画面遷移にイベント追加
  docDom.querySelectorAll(`[${tagAttribute.href}]`).forEach((dom) => {
    dom.setAttribute("href", "#");

    dom.onclick = () => {
      const href = dom.getAttribute(tagAttribute.href);
      postLog(logType.transition, dom.innerText, href);
      selectMethod(href);
    };
  });

  // ページ移動にイベント追加
  docDom.querySelectorAll(`[${tagAttribute.link}]`).forEach((dom) => {
    const href = dom.getAttribute(tagAttribute.link);
    dom.setAttribute("href", href);

    dom.onmousedown = (event) => {
      if (event.button == 0 || event.button == 1) {
        // マウスの左か中ボタンが押下された場合
        postLog(logType.transition, dom.innerText, "");
      }
    };
  });

  // モーダル表示にイベント追加
  docDom.querySelectorAll(`[${tagAttribute.modal}]`).forEach((dom) => {
    dom.setAttribute("href", "#");
    dom.setAttribute("data-bs-toggle", "modal");
    dom.setAttribute("data-bs-target", "#SubContent");

    dom.onclick = () => {
      const href = dom.getAttribute(tagAttribute.modal);
      postLog(logType.modal, dom.innerText, href);
      selectModalMethod(href);
    };
  });
}
