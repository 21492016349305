
<!-----------------------------------
  video.js
  video.jsの表示を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <video class="video-js" v-bind:src="src ?? ''" controls loop autoplay muted controlslist="nodownload" ref="videoPlayer">
    <source v-bind:src="src ?? ''" />
  </video>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, toRefs, onMounted, onBeforeUnmount } from "vue";
import videojs from "video.js";

export default defineComponent({
  name: "VideoPlayer",

  // 継承プロパティ定義
  props: {
    options: {},
    src: null,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup(props) {
    const videoPlayer = ref(null);

    const { options } = toRefs(props);
    /** @type {import("vue").Ref<Readonly<{instance:import("video.js").VideoJsPlayer}>>} */
    let player = ref();
    onMounted(() => {
      player.value = Object.freeze({ instance: videojs(videoPlayer.value, options) });
    });
    onBeforeUnmount(() => {
      player.value?.instance?.dispose();
    });

    return { videoPlayer, player };
  },
  
  //*****************************
  // プロパティ定義
  //*****************************
  data() { return {}; },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped></style>
