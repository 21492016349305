import defaultAxios from "axios";
import axios, { getApiUrl, apiWait } from "@/helpers/AxiosHelper";
import { IsLocal } from "./IsLocal";

/**
 * @typedef {{
 *   IsMaintenance: boolean;
 *   Start: string;
 *   End: string;
 * }} MaintenanceCallbackData
 * 
 * @typedef {(response:MaintenanceCallbackData)=>void} MaintenanceCallback
 */
/**
 * メンテナンス設定のコールバック関数
 * @type {MaintenanceCallback}
 */
let maintenanceCallback;
/**
 * メンテナンスコールバック関数の登録
 * @param {MaintenanceCallback} callback 
 */
function setMaintenanceCallback(callback) {
  maintenanceCallback = callback;
}
/**
 * メンテナンスコールバック関数を実行する
 * @param {MaintenanceCallbackData} params 
 */
function invokeMaintenanceCallback(params) {
  if (maintenanceCallback != null && typeof maintenanceCallback == "function") {
    maintenanceCallback(params);
  }
}

/**
 * メンテナンス確認API
 */
async function apiMaintenanceGet() {
  try {
    if (IsLocal) {
      /** @type {import("axios").AxiosResponse<MaintenanceCallbackData>} */
      const res = await defaultAxios.get(process.env.VUE_APP_MAINTENANCE);
      invokeMaintenanceCallback(res.data);
    } else {
      /** @type {import("axios").AxiosResponse<MaintenanceCallbackData>} */
      const res = await defaultAxios.get("/maintenance");
      invokeMaintenanceCallback(res.data);
    }
  } catch (error) {
    invokeMaintenanceCallback({ IsMaintenance: true });
  }
}

/**
 * @template {string} N
 * @template T
 * @typedef {import("axios").AxiosResponse<{
 *   API: N;
 *   Data: T;
 * }>} ApiResponse DDRAtlasAPIレスポンス
 */

/**
 * 検索API
 * @param {ApiSearchPageGetRequest} params
 * @returns 
 * 
 * @typedef {{
 *   searchkey: string;
 * }} ApiSearchPageGetRequest
 * @typedef {string} ApiSearchPageGetResponse
 */
async function apiSearchPageGet(params) {
  try {
    /** @type {ApiResponse<"/atlas/search/page", ApiSearchPageGetResponse[]>} */
    const res = await axios.get(getApiUrl("search/page"), { params: params });
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * 患者データの検索API
 * @param {ApiSearchPatientGetRequest} params
 * @returns 
 * 
 * @typedef {{
 *   gender: string[];
 *   age: string[];
 *   bmi: string[];
 *   disease: string[];
 * }} ApiSearchPatientGetRequest
 * @typedef {{
 *   Id: string;
 *   PatientId: string;
 *   Gender: string;
 *   Age: string;
 *   Bmi: string;
 *   Height: string;
 *   Url: string;
 *   Disease: string;
 *   DiseaseOrder: string;
 * }} ApiSearchPatientGetResponse
 */
async function apiSearchPatientGet(params) {
  try {
    /** @type {ApiResponse<"/atlas/search/patient", ApiSearchPatientGetResponse[]>} */
    const res = await axios.get(getApiUrl("search/patient"), { params: params });
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ログイン状態の取得API
 * @returns 
 * 
 * @typedef {{
 *   userid: string;
 *   username: string;
 *   access: boolean;
 * }} ApiUserinfoGetResponse
 */
async function apiUserinfoGet() {
  try {
    /** @type {ApiResponse<"/atlas/userinfo", ApiUserinfoGetResponse>} */
    const res = await axios.get(getApiUrl("userinfo"));
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * アクセスログの登録API
 * @param {ApiLogPostRequest} params
 * @returns 
 * 
 * @typedef {{
 *   LogType: string;
 *   Content: string;
 *   Source: string;
 *   Destination: string;
 * }} ApiLogPostRequest
 * @typedef {"OK"} ApiLogPostResponse
 */
async function apiLogPost(params) {
  try {
    /** @type {ApiResponse<"/atlas/log", ApiLogPostResponse>} */
    const res = await axios.post(getApiUrl("log"), params);
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ブックマークの削除API
 * @param {ApiBookmarkDeleteRequest} params
 * @returns 
 * 
 * @typedef {{
 *   id: string;
 * }} ApiBookmarkDeleteRequest
 * @typedef {"OK"} ApiBookmarkDeleteResponse
 */
async function apiBookmarkDelete(params) {
  try {
    /** @type {ApiResponse<"/atlas/bookmark", ApiBookmarkDeleteResponse>} */
    const res = await axios.delete(getApiUrl("bookmark"), { params: params });
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ブックマークの登録API
 * @param {ApiBookmarkPostRequest} params
 * @returns 
 * 
 * @typedef {{
 *   Id: string;
 * }} ApiBookmarkPostRequest
 * @typedef {"OK"} ApiBookmarkPostResponse
 */
async function apiBookmarkPost(params) {
  try {
    /** @type {ApiResponse<"/atlas/bookmark", ApiBookmarkPostResponse>} */
    const res = await axios.post(getApiUrl("bookmark"), params);
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * コンテンツツリーの取得表示API
 * @returns 
 * 
 * @typedef {{
 *   DisplayContent: import("@/helpers/type").ContentData[];
 *   UserName: string;
 * }} ApiMenuGetResponse
 */
async function apiMenuGet() {
  try {
    /** @type {ApiResponse<"/atlas/menu", ApiMenuGetResponse>} */
    const res = await axios.get(getApiUrl("menu"));
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ファイル有無確認API
 * @param {ApiFileexistsGetRequest[]} params
 * @returns 
 * 
 * @typedef {{
 *   tag: string;
 *   url: string;
 * }} ApiFileexistsGetRequest
 * @typedef {{
 *   Tag: string;
 *   Url: string;
 *   Exists: boolean;
 * }} ApiFileexistsGetResponse
 */
async function apiFileexistsGet(params) {
  try {
    /** @type {ApiResponse<"/atlas/fileexists", ApiFileexistsGetResponse[]>} */
    const res = await axios.get(getApiUrl("fileexists"), { params: params });
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * お知らせ件数取得
 * @returns 
 * 
 * @typedef {number} ApiNewsGetCountResponse
 */
async function apiNewsCountGet() {
  try {
    /** @type {ApiResponse<"/atlas/news", ApiNewsGetResponse>} */
    const res = await axios.get(getApiUrl("news"));

    let count = 0;
    for (const notice of res.data.Data) {
      if (!notice.read) {
        count++;
      }
    }

    return count;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * お知らせ取得
 * @returns 
 * 
 * @typedef {{
 *   id: string;
 *   date: string;
 *   title: string;
 *   read: boolean;
 * }[]} ApiNewsGetResponse
 */
async function apiNewsGet() {
  try {
    /** @type {ApiResponse<"/atlas/news", ApiNewsGetResponse>} */
    const res = await axios.get(getApiUrl("news"));
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * お知らせ既読更新
 * @param {ApiNewsPostRequest} params
 * @returns 
 * 
 * @typedef {{id: string;}} ApiNewsPostRequest
 * @typedef {"OK"} ApiNewsPostResponse
 */
async function apiNewsPost(params) {
  try {
    /** @type {ApiResponse<"/atlas/news", ApiNewsPostResponse>} */
    const res = await axios.post(getApiUrl("news"), params);
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ユーザー設定取得
 * @returns 
 * 
 * @typedef {import("@/helpers/type").UserConfig} ApiConfigGetResponse
 */
async function apiConfigGet() {
  try {
    /** @type {ApiResponse<"/atlas/config", ApiConfigGetResponse>} */
    const res = await axios.get(getApiUrl("config"));
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

/**
 * ユーザー設定更新
 * @param {ApiConfigPostRequest} params
 * @returns 
 * 
 * @typedef {ApiConfigGetResponse} ApiConfigPostRequest
 * @typedef {ApiConfigGetResponse} ApiConfigPostResponse
 */
async function apiConfigPost(params) {
  try {
    /** @type {ApiResponse<"/atlas/config", ApiConfigPostResponse>} */
    const res = await axios.post(getApiUrl("config"), params);
    return res.data.Data;
  } catch (error) {
    apiMaintenanceGet();
    throw error;
  }
}

export {
  apiWait,

  apiSearchPageGet,
  apiSearchPatientGet,
  apiUserinfoGet,
  apiLogPost,
  apiBookmarkDelete,
  apiBookmarkPost,
  apiMenuGet,
  apiFileexistsGet,
  apiNewsCountGet,
  apiNewsGet,
  apiNewsPost,
  apiConfigGet,
  apiConfigPost,

  apiMaintenanceGet,
  setMaintenanceCallback,
};
