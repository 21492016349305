<!-----------------------------------
  スクロールトップを右下に表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="position-relative">
    <a
      v-bind:href="isModal ? '#head-top-modal' : '#head-top'"
      class="scroll-top position-absolute bottom-0 end-0"
      v-on:click.stop="postLog"
      ><img src="../../assets/btn_page_top.png" alt="top"
    /></a>
  </div>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent } from "vue";
import LogHelper from "@/helpers/LogHelper";

export default defineComponent({
  name: "ScrollTop",
  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    contentId: String,
    isModal: Boolean,
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    //================================
    // リンク押下ログ送信処理
    //================================
    postLog() {
      LogHelper.postLog(
        LogHelper.log.scroll,
        "TOP",
        this.contentId,
        this.contentId
      );
    },
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
.scroll-top {
  margin: 1rem;
}
.scroll-top > img {
  width: 40px;
  height: 40px;
}
</style>
