<!-----------------------------------
  患者データの気管径の表を表示する
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div>
    <br>
    <table class="table table01">
      <thead>
        <tr>
          <th colspan="2">呼気開始時</th>
          <th colspan="2">呼気終了時</th>
          <th rowspan="2">狭窄量<br />[mm]</th>
          <th rowspan="2">狭窄率<br />[%]</th>
        </tr>
        <tr>
          <th>時刻[s]</th>
          <th>気道径最拡張部<br />[mm]</th>
          <th>時刻[s]</th>
          <th>気道径最狭小部<br />[mm]</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ startFrame }}</td>
          <td>{{ startBD }}</td>
          <td>{{ endFrame }}</td>
          <td>{{ endBD }}</td>
          <td>{{ stenosisAmount }}</td>
          <td>{{ stenosisPercent }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "PatientDataBD",

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    /**
     * @typedef {{
     *   startFrame: number;
     *   startBD: number;
     *   endFrame: number;
     *   endBD: number;
     *   stenosisAmount: number;
     *   stenosisPercent: number;
     * }} BDData BDデータ
     * 
     * @type {{new(): BDData;}} */
    bdData: Object,
    fps: Number,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup(props) {
    const startFrame = computed(() => {
      if (props.bdData?.startFrame != null && props.fps != null && !isNaN(props.bdData.startFrame)) {
        const sec = props.bdData.startFrame / props.fps;
        return sec.toFixed(2);
      } else {
        return "-";
      }
    });
    const startBD = computed(() => {
      if (props.bdData?.startBD != null && !isNaN(props.bdData.startBD)) {
        return props.bdData.startBD.toFixed(1);
      } else {
        return "-";
      }
    });
    const endFrame = computed(() => {
      if (props.bdData?.endFrame != null && props.fps != null && !isNaN(props.bdData.endFrame)) {
        const sec = props.bdData.endFrame / props.fps;
        return sec.toFixed(2);
      } else {
        return "-";
      }
    });
    const endBD = computed(() => {
      if (props.bdData?.endBD != null && !isNaN(props.bdData.endBD)) {
        return props.bdData.endBD.toFixed(1);
      } else {
        return "-";
      }
    });
    const stenosisAmount = computed(() => {
      if (props.bdData?.stenosisAmount != null && !isNaN(props.bdData.stenosisAmount)) {
        return props.bdData.stenosisAmount.toFixed(1);
      } else {
        return "-";
      }
    });
    const stenosisPercent = computed(() => {
      if (props.bdData?.stenosisPercent != null && !isNaN(props.bdData.stenosisPercent)) {
        return props.bdData.stenosisPercent.toFixed(1) + "%";
      } else {
        return "-";
      }
    });

    return {
      startFrame,
      startBD,
      endFrame,
      endBD,
      stenosisAmount,
      stenosisPercent,
    };
  },
});
</script>

<style scoped>
thead tr th {
  border-bottom: none;
}
</style>
