
<!-----------------------------------
  お知らせビューア
  お知らせの一覧表示の実施を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="py-1">
    <div class="d-flex flex-column news-size" ref="TableClient">
      <perfect-scrollbar class="flex-grow-1" ref="TextContentScroll">
        <div class="news-rect" ref="HtmlContent">
          <TanstackTableVue v-if="0 < this.columns.length" class="news-table" :itemSource="noticeList" :columns="columns"
            v-slot="{ cell }" tag="NoticeViewer">
            <!-- 未読表示 -->
            <template v-if="cell.column.id == 'read' && !cell.row.original[cell.column.id]">
              <span class="badge bg-danger align-middle">未読</span>
            </template>
            <!-- 日付表示 -->
            <template v-if="cell.column.id == 'date'">
              <span class="align-middle">{{ cell.row.original[cell.column.id] }}</span>
            </template>

            <!-- タイトル表示 -->
            <template v-if="cell.column.id == 'title'">
              <a href="#" data-bs-toggle="modal" data-bs-target="#NoticeDetails"
                @click.left="onLinkClick(cell.row.original)">
                <span v-html="searchHighlight(cell.row.original[cell.column.columnDef.accessorKey], searchKey)"></span>
              </a>
            </template>
          </TanstackTableVue>
        </div>
      </perfect-scrollbar>
    </div>
  </div>

  <!-- 患者データポップアップ -->
  <teleport to="body">
    <div id="NoticeDetails" class="modal" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="ModalClose"></button>
          </div>
          <div class="modal-body">
            <div class="fs-5 mt-1">{{ selected.date }}</div>
            <div class="fs-4 mt-1 mb-3">{{ selected.title }}</div>
            <div v-html="searchHighlight(newsDoc, searchKey)" ref="NewsContent"></div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import TanstackTableVue from "@/components/Utility/TanstackTable.vue";
import { createColumnHelper } from "@tanstack/vue-table";
import ContentHelper from "@/helpers/ContentHelper";
import LogHelper from "@/helpers/LogHelper";
import { apiNewsGet, apiNewsPost } from "@/helpers/ApiHelper";
import customAxios from "@/helpers/AxiosHelper";

/**
 * @typedef {{
 *  id: string;
 *  date: string;
 *  title: string;
 *  read: boolean;
 * }} NoticeData
 */

export default defineComponent({
  name: "NoticeViewer",

  components: {
    TanstackTableVue,
  },

  //*****************************
  // 継承イベント定義
  //*****************************
  emits: {
    /** @type {(value:string)=>void} */
    selectMethod: null,
    /** @type {(value:string,toggleModal:string)=>void} */
    selectModalMethod: null,
    /** @type {()=>void} */
    menuOptionsRefresh: null,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    contentId: String,
    searchKey: String,
  },

  setup() {

    // テキストコンテンツ用のリサイズ検知
    /** @type {import("vue").Ref<HTMLElement>} */
    const HtmlContent = ref();
    /** @type {import("vue").Ref<import("perfect-scrollbar").default>} */
    const TextContentScroll = ref();
    /** @type {ResizeObserver} */
    let contentObserver;
    onMounted(() => {
      contentObserver = new ResizeObserver(() => {
        TextContentScroll.value?.update();
      });
      contentObserver.observe(HtmlContent.value);
    });
    onBeforeUnmount(() => {
      contentObserver?.disconnect();
    });

    // お知らせ情報を取得
    /** @type {import("vue").Ref<NoticeData[]>} */
    const noticeList = ref([]);
    apiNewsGet()
      .then((res) => { noticeList.value = res; })
      .catch(() => { noticeList.value = []; });

    // テーブル列設定
    const columnHelper = createColumnHelper();
    const columns = ref([]);
    /** @type {import("vue").Ref<HTMLElement>} */
    const TableClient = ref();
    onMounted(() => {
      // テーブルのmargin分を確保
      const width = TableClient.value.clientWidth - (9.6 * 2);

      columns.value = [
        columnHelper.accessor("read", {
          header: "",
          cell: (info) => info.getValue(),
          size: 50,
        }),
        columnHelper.accessor("date", {
          header: "日付",
          cell: (info) => info.getValue(),
          size: 110,
        }),
        columnHelper.accessor("title", {
          header: "お知らせ",
          cell: (info) => info.getValue(),
          size: width - (50 + 110),
        }),
      ];
    });
    return {
      HtmlContent,
      TextContentScroll,

      noticeList,
      columns,
      TableClient,
    };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /**
       * 選択中のお知らせ
       * @type {NoticeData}
       */
      selected: {},
      /**
       * お知らせコンテンツ
       * @type {string}
       */
      newsContent: "",

      videojs: Object.freeze({ instance: new ContentHelper.VideoJs() }),
    };
  },

  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    // html表示項目
    newsDoc() {
      let doc = this.newsContent;

      if (this.videojs.instance) this.videojs.instance.reset();
      doc = ContentHelper.searchHighlight(doc, this.searchKey);
      nextTick(() => {
        LogHelper.setOnclickEvent(
          this.content,
          this.$refs["NewsContent"],
          this.selectMethod,
          (value) => { this.selectModalMethod(value, "#NoticeDetails"); }
        );

        this.videojs.instance.set(this.$refs["NewsContent"]);
      });
      return doc;
    },
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * タイトルリンク選択処理
     * @param {NoticeData} link 
     */
    onLinkClick(link) {
      this.selected = link;

      this.updateUnread();
      this.newsContent = "";
      customAxios.get(`Content/News/news-${link.id}.html`)
        .then((res) => { this.newsContent = res.data; })
        .catch(() => { this.newsContent = ""; });
      LogHelper.postLog(LogHelper.log.modal, link.title, link.id, "");
    },

    /** 未読更新 */
    async updateUnread() {
      const notice = this.selected;
      if (!notice.read) {
        apiNewsPost({ id: notice.id })
          .then(() => { this.$emit("menuOptionsRefresh"); })
          .catch((e) => { console.error(e); });
        const index = this.noticeList.findIndex((v) => v.id == notice.id);
        this.noticeList[index].read = true;
      }
    },

    /**
     * ページ遷移処理
     * @param {string} value 選択コンテンツ
     */
    selectMethod(value) {
      this.$refs["ModalClose"].click();
      this.$emit("selectMethod", value);
    },

    /**
     * モーダル表示処理
     * @param {string} value 選択コンテンツ
     * @param {string} toggleModal トグルモーダル
     */
    selectModalMethod(value, toggleModal) {
      this.$emit("selectModalMethod", value, toggleModal);
    },

    //================================
    // 検索ワード強調表示
    //================================
    searchHighlight: ContentHelper.searchHighlight,
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
.news-size {
  /* 縦幅 - テーブル余白 */
  height: calc(var(--ddr-content-height) - 0.5rem);
}

.pc .news-size {
  width: calc(100vw - var(--ddr-sidemenu-width));
}

.mobile .news-size {
  width: 100vw;
}

.news-rect {
  width: max-content;
}

.news-size>div {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.news-table {
  table-layout: fixed;
}

/* ダイアログ幅 */
.modal-dialog {
  max-width: min(800px, calc(100% - 1rem));
}

/* ダイアログ高さ */
.modal-body {
  height: calc(100vh - (70px * 2) - 49px);
}
</style>
