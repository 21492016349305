<!-----------------------------------
  サイドメニュー
  ツリー表示及びフィルタリング、検索を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="SideMenu">
    <!-- 検索フォーム -->
    <div class="Search">
      <form class="d-flex gap-1 align-items-center h-100" @submit.prevent="search()">
        <div class="flex-grow-1">
          <div class="input-group input-group-sm SearchInput">
            <input type="text" class="form-control" v-model="searchKey" placeholder="検索ワード" />
            <button type="submit" class="btn btn-secondary text-nowrap">検索</button>
          </div>
        </div>
        <div v-if="searchData && searchData.length > 0">
          <span class="text-nowrap">{{ searchData.length }}件</span>
        </div>
        <div v-if="searchData && searchData.length > 0">
          <button type="button" class="btn-close btn-close-white align-middle" aria-label="Close"
            @click="deleteSearch()"></button>
        </div>
      </form>
    </div>

    <!-- 展開ボタン -->
    <div class="Expand p-1">
      <div class="d-flex gap-2">
        <button type="button" class="flex-fill btn btn-sm btn-secondary" @click="menuExpand()">
          全て開く
        </button>
        <button type="button" class="flex-fill btn btn-sm btn-secondary" @click="menuContract()">
          全て閉じる
        </button>
      </div>
    </div>

    <!-- コンテンツツリー表示 -->
    <perfect-scrollbar class="pb-3" ref="MenuTreeDom">
      <MenuTree :content="contentState" :select="select" :activeSelect="activeSelect" :searchKey="execSearchKey"
        :searchData="searchData" :options="options" :showFolder="showFolder" @TreeClickProp="TreeClickProp"
        @onRightclick="onRightclick" @selectMethod="selectMethod" />
    </perfect-scrollbar>
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, nextTick, ref } from "vue";
import { useStore } from "vuex";
import MenuTree from "@/components/MenuTree.vue";
import { apiSearchPageGet, apiNewsCountGet } from "@/helpers/ApiHelper";
import customAxios from "@/helpers/AxiosHelper";

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 */

export default defineComponent({
  name: "SideMenu",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    MenuTree,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    /**
     * 選択状態
     * @type {StringConstructor}
     */
    select: null,
    /** 表示コンテンツツリー */
    content: {
      /** @type {import("vue").PropType<ContentData[]>} */
      type: Array,
      default() {
        return [];
      },
    },
  },

  setup(props) {
    /** @type {import("vue").Ref<ContentData[] | null>} */
    let contentState = ref(useStore().state.ContentTreeState);
    // console.log("SideMenu:data:");
    // console.log(ContentState);
    if (contentState.value == null) {
      contentState = ref(props.content);
      useStore().commit("setContentTreeState", contentState.value);
    }

    const activeSelect = ref(props.select);

    return {
      contentState,
      activeSelect,
    }
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    // フィルター類を初期化
    return {
      /** 検索キー */
      searchKey: "",
      /**
       * 検索結果
       * @type {string[]}
       */
      searchData: [],
      /**
       * 検索した検索キー
       * @type {string | null}
       */
      execSearchKey: "",

      /** オプション */
      options: {
        /** アーカイブ表示 */
        archiveDisplay: false,
        /** 未読お知らせ件数 */
        unreadNotice: 0,
      },
    };
  },
  //*****************************
  // プロパティ変更監視
  //*****************************
  watch: {
    // コンテンツ変更の反映
    content: {
      handler: function (newVal) {
        // 新値へメニュー展開状態を反映してから入れ替え
        this.copyOpen(this.contentState, newVal);
        this.contentState = newVal;

        if (this.$store.state.ContentTreeState == null) {
          // 初回表示時は初期展開設定を反映
          this.initMenuOpen(this.contentState);
        } else {
          // 2回目以降は直前の展開状況を反映
          this.copyOpen(this.$store.state.ContentTreeState, this.contentState);
        }
        this.$store.commit("setContentTreeState", this.contentState);
        this.updateTreeScroll();
      },
    },
    select: {
      handler: function (newVal, oldVal) {
        // 遷移先のコンテンツがあるフォルダを開く
        if (this.setDeployFolder(this.contentState, this.select)) {
          this.activeSelect = newVal;
        } else {
          this.activeSelect = oldVal;
        }
        this.$store.commit("setContentTreeState", this.contentState);
        this.updateTreeScroll();
      },
    },
  },

  //*****************************
  // マウント後イベント
  //*****************************
  mounted() {
    // オプション更新
    this.refresh();

    // アーカイブフォルダ表示フラグ
    customAxios.get("config.json")
      .then((res) => { this.options.archiveDisplay = (res.data["archive-display"] == 1); })
      .catch(() => { this.options.archiveDisplay = false; })
  },

  //*****************************
  // 算出プロパティ
  //*****************************
  computed: {
    ContentTreeState() {
      return this.$store.state.ContentTreeState;
    },
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * 検索実行
     */
    async search() {
      if (this.searchKey == null || this.searchKey == "") {
        alert("検索ワードを入力してください");
        return;
      }
      const response = await apiSearchPageGet({ searchkey: this.searchKey });
      // 0件の場合メッセージを表示する
      if (response.length == 0) {
        alert("該当する項目がありません");
        return;
      }

      // 1000件の場合メッセージを表示する
      if (response.length >= 1000) {
        alert("該当項目が多すぎます");
        return;
      }

      this.searchData = response;
      console.log(this.searchData);
      this.sxecSearchKey = this.searchKey;
      this.$emit("setSearchData", this.searchKey);
    },
    /**
     * 検索削除
     */
    deleteSearch() {
      this.searchData = [];
      this.sxecSearchKey = null;
      this.$emit("setSearchData", null);
    },

    /**
     * メニュー展開
     */
    menuExpand() {
      this.setOpenAll(true, this.contentState);
      this.$store.commit("setContentTreeState", this.contentState);
      this.updateTreeScroll();
    },
    /**
     * メニュー縮小
     */
    menuContract() {
      this.setOpenAll(false, this.contentState);
      this.$store.commit("setContentTreeState", this.contentState);
      this.updateTreeScroll();
    },

    /**
     * コンテンツツリーのオープン状態複写
     * @param {ContentData[]} src コピー元
     * @param {ContentData[]} dest コピー先
     */
    copyOpen(src, dest) {
      if (src != null) {
        // 全要素に対して処理を行う
        for (let i = 0; i < Object.keys(src).length; i++) {
          // 子要素が存在する＝フォルダとして処理する
          if (src[i].child != void 0) {
            // Openが設定されているならばIsOpenをコピーする
            if (src[i].Open != void 0) {
              // 指定されたIDのフォルダに対してIsOpenの値をコピーする
              this.setOpenToId(src[i].Open, src[i].id, dest);
            }
            // 子要素に対して再帰呼び出し
            this.copyOpen(src[i].child, dest);
          }
        }
      }
    },
    /**
     * 指定されたIDのフォルダにIsOpenを設定する
     * @param {boolean} Open 開閉状態
     * @param {string} id 対象ID
     * @param {ContentData[]} dest 検索対象データ
     */
    setOpenToId(Open, id, dest) {
      for (let i = 0; i < Object.keys(dest).length; i++) {
        if (dest[i].child != void 0) {
          if (dest[i].id == id) {
            dest[i].Open = Open;
            return;
          } else {
            this.setOpenToId(Open, id, dest[i].child);
          }
        }
      }
    },
    /**
     * 初期表示時に設定フォルダを開く
     * @param {ContentData[] | null} content メニューツリー
     */
    initMenuOpen(content) {
      if (content != null) {
        for (const data of content) {
          if (data.type == "folder" && data.opened == 1) {
            data.Open = true;
          }
          this.initMenuOpen(data.child);
        }
      }
    },

    /**
     * 対象のコンテンツまでフォルダを開く
     * @param {ContentData[]} content 検索対象のコンテンツデータ
     * @param {string} id 検索対象のコンテンツID
     * @returns アーカイブ選択状態
     */
    setDeployFolder(content, id) {
      if (content != null) {
        for (let i = 0; i < Object.keys(content).length; i++) {
          if (content[i].id == id) {
            return true;
          } else {
            if (this.showFolder(content[i])) {
              if (this.setDeployFolder(content[i].child, id)) {
                // 子に対象IDが存在する場合はフォルダを開く
                this.setOpenToId(true, content[i].id, this.contentState);
                return content[i].name != "ブックマーク";
              }
            }
          }
        }
      }
      return false;
    },
    /**
     * フォルダ表示判定
     * @param {ContentData} content 対象コンテンツ
     * @returns 
     */
    showFolder(content) {
      /** @type {import("@/helpers/type").UserConfig} */
      const userConfig = this.$store.state.UserConfig;

      if (content.name == "アーカイブ") {
        if (userConfig?.showArchive == true) {
          // ユーザー設定で表示設定の場合は表示
          return true;
        }
        if (this.options?.archiveDisplay == true) {
          // 全体設定で表示設定の場合は表示
          return true;
        }

        // 表示設定が無ければ非表示
        return false;
      }
      return true;
    },
    /**
     * フォルダを全て開く
     * @param {boolean} open 開閉状態
     * @param {ContentData[]} content 検索対象のコンテンツデータ
     */
    setOpenAll(open, content) {
      if (content != null) {
        for (let i = 0; i < Object.keys(content).length; i++) {
          if (content[i].child != null) {
            content[i].Open = open;
            this.setOpenAll(open, content[i].child);
          }
        }
      }
    },

    /**
     * コンテンツ選択
     * @param {string} value 選択コンテンツ
     */
    selectMethod(value) {
      this.$emit("selectMethod", value);
    },
    /**
     * 右クリックメニュー表示
     * @param {ContentData} value 対象データ
     * @param {MouseEvent} e クリックイベント情報
     */
    onRightclick(value, e) {
      this.$emit("onRightclick", value, e);
    },
    /**
     * ツリークリック状態更新
     */
    TreeClickProp() {
      this.$store.commit("setContentTreeState", this.contentState);
      this.updateTreeScroll();
    },

    /** メニュースクロールの更新 */
    updateTreeScroll() {
      // DOM更新後にスクロール更新を行う
      nextTick(()=>{
        /** @type {import("perfect-scrollbar/types/perfect-scrollbar").default} */
        const menu = this.$refs["MenuTreeDom"];
        menu.update();
      });
    },

    /** リフレッシュ */
    refresh() {
      apiNewsCountGet()
        .then((res) => { this.options.unreadNotice = res; })
        .catch(() => { this.options.unreadNotice = 0; });
    },
  },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
.SideMenu {
  min-width: var(--ddr-sidemenu-width);
  max-width: var(--ddr-sidemenu-width);
  background: var(--ddr-sidemenu-background);
  color: white;

  --ddr-sidemenu-expand-height: 39px;
}

.Search {
  height: var(--ddr-content-header-height);
  padding-left: 4px;
  padding-right: 4px;
}

.SearchInput>input.form-control,
.SearchInput>button.btn {
  border-radius: 0px;
}

.Expand {
  height: var(--ddr-sidemenu-expand-height);
}

.Expand button:focus {
  box-shadow: none;
}

.Search form div input {
  max-width: 100%;
}

.ps {
  height: calc(var(--ddr-content-height) - var(--ddr-sidemenu-expand-height));
  background: var(--ddr-sidemenu-background);
  z-index: 1;
}
</style>
