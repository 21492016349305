
<!-----------------------------------
  ユーザー設定
  ユーザー設定の表示を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="container-fluid mb-3">
    <div class="row justify-content-between align-items-center mb-3">
      <div class="col-auto fs-5">{{ userName }}様</div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
          data-bs-target="#PasswordModal">パスワード変更</button>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-auto">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="ShowArchiveCheck" v-model="config.showArchive" @change="update()">
          <label class="form-check-label" for="ShowArchiveCheck">
            X線動画像例のアーカイブを目次に表示する
          </label>
        </div>
      </div>
    </div>

    <!-- パスワード変更ポップアップ -->
    <teleport to="body">
      <div id="PasswordModal" class="modal" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" @click="showConfigModal" ref="PasswordModalClose"></button>
            </div>
            <div class="modal-body">
              <PasswordChangeVue @completed="showConfigModal" ref="PasswordChangeVue"/>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PasswordChangeVue from "@/components/Utility/PasswordChange.vue";
import { apiConfigGet, apiConfigPost } from "@/helpers/ApiHelper";

export default defineComponent({
  name: "UserConfig",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    PasswordChangeVue,
  },

  //*****************************
  // 継承イベント定義
  //*****************************
  emits: {
    /** @type {()=>void} */
    showConfigModal: null,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    userName: String,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup() {

    const store = useStore();
    /**
     * ユーザー設定
     * @type {import("vue").Ref<import("@/helpers/ApiHelper").ApiConfigGetResponse>} 
     */
    const config = ref({});
    apiConfigGet()
      .then((res) => { config.value = res; })
      .finally(() => { store.commit("setUserConfig", config.value); });

    return { config };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() { return {}; },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /** 設定更新 */
    async update() {
      try {
        const res = await apiConfigPost(this.config);
        this.config = res;
      }
      finally {
        this.$store.commit("setUserConfig", this.config);
      }
    },

    /** パスワード変更完了 */
    showConfigModal() {

      // パスワードリセット
      /** @type {InstanceType<PasswordChangeVue>} */
      const passwordChangeVue = this.$refs["PasswordChangeVue"];
      passwordChangeVue.clear();

      this.$emit("showConfigModal");
    },
  },
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped></style>
